<template>
	<div class="notFound">
		<div class="img">
			<img src="./404.jpg" >
		</div>
		<div class="link">
			<el-button type="primary" @click="goBack">返回上一页</el-button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'notFound',
	data() {
		return {
			
		};
	},
	methods: {
		goBack(){
			this.$router.go(-1)
		}
	},
	beforeCreate: function() {
		//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
	},
	created: function() {
		//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
	},
	beforeMount: function() {
		//模板编译/挂载之前
	},
	mounted: function() {
		//模板编译/挂载之后
	},
	beforeUpdate: function() {
		//组件更新之前
	},
	updated: function() {
		//组件更新之后
	},
	activated: function() {
		// for keep-alive，组件被激活时调用
	},
	deactivated: function() {
		// for keep-alive，组件被移除时调用
	},
	beforeDestory: function() {
		// 组件销毁调用
	},
	destoryed: function() {
		// 组件销毁后调用
	}
};
</script>

<style lang="less" scoped="scoped">
	.notFound{
		width: 100vw;
		height: 100vh;
		position: relative;
		.img{
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;
			img{
				height: 70%;
			}
		}
		.link{
			width: 100%;
			height: 50px;
			position: absolute;
			bottom: 150px;
			left: 0px;
			z-index: 9;
			display: flex;
			justify-content: center;
			.el-button{
				width: 300px;
				height: 50px;
				border-radius: 25px;
			}
		}
	}
</style>
