<template>
	<div class="admin">
		<div class="title">
			<div class="logo">
				<img src="../../assets/logo-title.png">
				中国信息协会法律分会
				<span>管理平台</span>
			</div>
			<div class="right">
				<div class="username">
					{{username}}
				</div>
				<div class="operation">
					<div class="item">
						<router-link to="/index" target="_blank">
							网站首页
						</router-link>
					</div>
					<div class="item" @click="doLogout">
						注销
					</div>
				</div>
			</div>
		</div>
		<div class="down">
			<div class="left">
				<el-collapse v-model="activeNames" @change="handleChange" accordion>
					<el-collapse-item class="deep" v-for="(item,index) in navigation" :key="index" :title="item.name"
						:name="item.name">
						<div :class="'link '+(active==tab.path?'link-active':'')" v-for="(tab,tabIndex) in item['tabs']"
							:key="tabIndex">
							<router-link :to="{path:tab.path}">
								{{tab.name}}
							</router-link>
						</div>
					</el-collapse-item>
				</el-collapse>
			</div>
			<div class="right">
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapMutations
	} from 'vuex'
	export default {
		name: 'admin',
		data() {
			return {
				username: '',
				activeNames: ['系统管理'],
				active: '/user',
				navigation: [{
						name: '系统管理',
						tabs: [{
								name: '用户管理',
								path: '/user'
							},
							// {
							// 	name: '部门管理',
							// 	path: '/department'
							// }
						]
					},
					{
						name: '网站管理',
						tabs: [{
							name: '文章列表',
							path: '/article'
						}, {
							name: '轮播图列表',
							path: '/slide'
						}]
					},
					{
						name: '会员管理',
						tabs: [{
							name: '会员列表',
							path: '/vip'
						}]
					}
				]
			};
		},
		watch: {
			$route(to) {
				let path = to.path
				let array = ['/user', '/department', '/article', '/slide', '/vip']
				if (array.indexOf(path) >= 0) {
					this.active = path
					this.$pub.setStorage('active', path)
				}
			}
		},
		methods: {
			...mapMutations(['logout']),
			handleChange(e) {
				this.$pub.setStorage('activeNames', e)
			},
			doLogout() {
				this.$confirm('请问是否确认退出登录？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$message({
						type: 'success',
						message: '退出登录成功',
						duration: 1500,
						onClose: () => {
							this.logout()
						}
					});
				}).catch(() => {});
			},
			getUserInfo(){
				this.$api.getUserInfo({},res=>{
					if(res.code==200){
						this.username = res.data.loginUser.realName
					}
				})
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
			if (this.$pub.getStorage('active')) {
				this.active = this.$pub.getStorage('active')
				this.activeNames = [this.$pub.getStorage('activeNames')]
			}
			this.getUserInfo()
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	.admin {
		width: 100vw;
		height: 100vh;
		min-width: 1440px;
		min-height: 800px;
		overflow: auto;

		.title {
			width: 100%;
			height: 100px;
			background: linear-gradient(#47a0ff, #036DEE);
			padding: 0px @vue-spacing-row-lg;
			box-sizing: border-box;
			display: flex;
			justify-content: space-between;

			.logo {
				height: 100%;
				display: flex;
				font-size: @vue-font-size-lg;
				align-items: center;
				color: @vue-text-color-inverse;

				span {
					font-weight: bold;
					margin-left: @vue-spacing-row-sm;
				}
			}

			.right {
				height: 100%;
				display: flex;
				justify-content: flex-end;

				.username,
				.operation {
					height: 100%;
					display: flex;
					align-items: center;
				}

				.username {
					font-size: @vue-font-size-sm + 4;
					color: @vue-text-color-inverse;
					font-weight: bold;
					margin-right: @vue-spacing-row-base;
				}

				.operation {

					.item,
					a {
						width: 100px;
						height: 40px;
						display: flex;
						justify-content: center;
						align-items: center;
						color: @vue-text-color-inverse;
						cursor: pointer;
					}

					.item:hover {
						font-weight: bold;
					}
				}
			}
		}

		.down {
			width: 100%;
			height: calc(100% - 100px);
			display: flex;
			justify-content: space-between;

			.left {
				width: 300px;
				height: 100%;
				border-right: 1px solid @vue-border-color;
				box-sizing: border-box;

				.deep {
					/deep/ .el-collapse-item__header {
						padding-left: @vue-spacing-row-base;
						box-sizing: border-box;
					}
				}

				.link {
					width: 100%;
					height: 50px;

					a {
						width: 100%;
						height: 100%;
						display: flex;
						align-items: center;
						padding-left: @vue-spacing-row-base*2;
						box-sizing: border-box;
						color: @vue-text-color;
					}
				}

				.link-active {
					background: @vue-bg-color-grey;

					a {
						color: @vue-text-color-active;
					}
				}
			}

			.right {
				width: calc(100% - 300px);
				height: 100%;
				overflow: auto;
			}
		}
	}
</style>
