<template>
	<div class="index">
		<div class="fixation">
			<div class="news">
				<div class="news-image">
					<div class="news-list">
						<!-- <div class="news-image" v-for="(item,index) in news" :key="index" v-show="active===index">
							<router-link :to="{path:'/detail',query:{id:item.id}}">
								<img :src="item.src">
							</router-link>
						</div> -->
						<el-carousel height="300px" :autoplay="true">
							<el-carousel-item v-for="(item,index) in news" :key="index">
								<router-link :to="{path:'/detail',query:{id:item.id}}">
									<img :src="item.articleImg" alt="">
								</router-link>
							</el-carousel-item>
						</el-carousel>
					</div>
					<!-- <div class="news-dots">
						<div :class="'news-item '+(active===index?'news-item-active':'')" v-for="(item,index) in news"
							:key="index">
							{{index+1}}
						</div>
					</div> -->
				</div>
				<div class="news-right">
					<div class="news-title">
						<div class="news-title-left">
							<div v-for="(item,index) in dynamic_news"
								:class="'news-tab '+(tabActive===index?'news-tab-active':'')" :key="index"
								@click="tabActive = index,fetchDynamic()">
								{{item.label}}
							</div>
						</div>
						<div class="news-title-right" v-if="dynamic_news.length>0">
							<router-link
								:to="{path:'/dynamic',query:{id:dynamic_news[tabActive]['id'],label:dynamic_news[tabActive]['label']}}">
								更多>>
							</router-link>
						</div>
					</div>
					<div class="news-flow custom-scroll-bar">
						<div class="news-item" v-for="(item,index) in flow" :key="index">
							<router-link :to="{path:'/detail',query:{id:item.id}}">
								<span>[ {{item.createAt}} ]</span>
								{{item.articleTitle.length>36?item.articleTitle.substring(0,35)+'...':item.articleTitle}}
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<div class="middle">
				<div class="middle-left">
					<div class="middle-top">
						<router-link :to="{path:'/blockchain'}">
							<img src="../../../assets/dataimg.jpg">
						</router-link>
					</div>
					<div class="middle-activity">
						<div class="activity-title">
							<div class="activity-left">
								<div class="padding"></div>
								分会活动
							</div>
							<router-link :to="{path:'/activity',query:{id:action_list.id}}">更多</router-link>
						</div>
						<ul class="activity-list">
							<li class="activity-item" v-for="(item,index) in activity" :key="index">
								<router-link :to="{path:'/detail',query:{id:item.id}}">
									{{item.articleTitle}}
								</router-link>
							</li>
						</ul>
					</div>
				</div>
				<div class="middle-right">
					<div class="about-title">
						<div class="about-word">
							关于我们
						</div>
					</div>
					<div class="about-content">
						<p v-html="about.articleContent"></p>
					</div>
				</div>
			</div>
			<div class="vip">
				<div class="vip-title">
					<div class="vip-word">
						分会会员
					</div>
					<div class="vip-more">
						<router-link to="/list">
							更多
						</router-link>
					</div>
				</div>
				<div class="vip-list">
					<el-table :data="tableData" style="width: 100%" height="340">
						<el-table-column prop="enterpriseName" label="会员单位名称" width="295" align="center" />
						<el-table-column prop="certificateNo" label="证书编号" width="295" align="center" />
						<el-table-column prop="population" label="证书有效日期" width="295" align="center" />
						<el-table-column prop="registerAt" label="发证日期" width="295" align="center" />
					</el-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'index',
		data() {
			return {
				news: [],
				active: 0,
				tabActive: 0,
				flow: [],
				activity: [],
				about: {},
				tableData: [],
				slide_news: [],
				dynamic_news: [],
				action_list: {},
				about_list: {}
			};
		},
		methods: {
			fetchCategory() {
				this.$api.getCategory({}, res => {
					if (res.code == 200) {
						this.slide_news = res.data[0]
						this.dynamic_news = res.data[3]['children']
						this.action_list = res.data[1]['children'][3]
						this.about_list = res.data[1]['children'][4]
						this.fetchArticle()
					}
				})
			},
			fetchArticle() {
				this.fetchSlide()
				this.fetchDynamic()
				this.fetchActivity()
				this.fetchAbout()
			},
			fetchSlide() {
				this.$api.getNewsList({
					articleType: this.slide_news.id,
					pageNum: 1,
					pageSize: 10
				}, res => {
					if (res.code == 200) {
						this.news = res.data.list
					}
				})
			},
			fetchDynamic() {
				this.$api.getNewsList({
					articleType: this.dynamic_news[this.tabActive].id,
					pageNum: 1,
					pageSize: 6
				}, res => {
					if (res.code == 200) {
						this.flow = res.data.list
					}
				})
			},
			fetchActivity() {
				this.$api.getNewsList({
					articleType: this.action_list.id,
					pageNum: 1,
					pageSize: 6
				}, res => {
					if (res.code == 200) {
						this.activity = res.data.list
					}
				})
			},
			fetchAbout() {
				this.$api.getNewsList({
					articleType: this.about_list.id,
					pageNum: 1,
					pageSize: 6
				}, res => {
					if (res.code == 200) {
						this.about = res.data.list[0]
					}
				})
			},
			fetchVip() {
				this.$api.getVipList({
					pageNum: 1,
					pageSize: 6
				}, res => {
					if (res.code == 200) {
						this.tableData = res.data.list
					}
				})
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
			this.fetchCategory()
			this.fetchVip()
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	.index {
		width: 100%;
		background: @vue-bg-color-grey;
		padding: @vue-spacing-col-lg 0px;
		box-sizing: border-box;

		.news {
			width: 100%;
			height: 300px;
			display: flex;
			justify-content: space-between;

			.news-image {
				width: 500px;
				height: 100%;
				position: relative;

				.news-list,
				.news-image,
				a,
				img {
					width: 100%;
					height: 100%;
				}

				.news-dots {
					width: 100%;
					height: 20px;
					position: absolute;
					right: 0px;
					bottom: 10px;
					display: flex;
					justify-content: flex-end;

					.news-item {
						width: 20px;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						background: @vue-bg-color-mask;
						color: @vue-text-color-inverse;
						font-size: @vue-font-size-min;
						margin-right: @vue-spacing-row-sm;
					}

					.news-item-active {
						border: 1px solid @vue-border-color-red;
						box-sizing: border-box;
					}
				}
			}

			.news-right {
				width: 670px;
				height: 100%;
				background: @vue-bg-color;
				box-shadow: 0px 0px 8px @vue-border-color;
				padding: 10px;
				box-sizing: border-box;
				font-size: @vue-font-size-min + 2;

				.news-title {
					width: 100%;
					height: 40px;
					display: flex;
					justify-content: space-between;

					.news-title-left {
						width: calc(100% - 100px);
						height: 100%;
						display: flex;

						.news-tab {
							width: 100px;
							height: 100%;
							display: flex;
							justify-content: center;
							align-items: center;
						}

						.news-tab-active {
							background: @vue-color-primary;
							color: @vue-text-color-inverse;
						}
					}

					.news-title-right {
						width: 100px;
						height: 100%;

						a {
							width: 100%;
							height: 100%;
							display: flex;
							justify-content: center;
							align-items: center;
							color: @vue-text-color-active;
						}
					}
				}

				.news-flow {
					width: 100%;
					height: 240px;
					overflow: auto;

					.news-item {
						width: 100%;
						height: 40px;
						box-sizing: border-box;
						border-bottom: 1px dashed @vue-border-color;

						a {
							width: 100%;
							height: 100%;
							display: flex;
							align-items: center;
							color: @vue-text-color;

							span {
								color: @vue-text-color-grey;
								margin-right: @vue-spacing-row-sm;
							}
						}

						a:hover {
							color: @vue-text-color-active;
						}
					}
				}
			}
		}

		.middle {
			width: 100%;
			height: 300px;
			margin-top: @vue-spacing-col-lg;
			display: flex;
			justify-content: space-between;

			.middle-left {
				width: 300px;
				height: 100%;

				.middle-top {
					width: 100%;
					height: 100px;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.middle-activity {
					width: 100%;
					height: 180px;
					margin-top: 20px;
					background: @vue-bg-color;
					box-shadow: 0px 0px 10px @vue-border-color;
					padding: 0px 10px;
					box-sizing: border-box;

					.activity-title {
						width: 100%;
						height: 30px;
						display: flex;
						justify-content: space-between;

						.activity-left {
							height: 100%;
							display: flex;
							align-items: center;
							font-size: @vue-font-size-min + 2;

							.padding {
								width: 4px;
								height: 16px;
								background: @vue-color-primary;
								margin-right: @vue-spacing-row-sm;
							}
						}

						a {
							height: 100%;
							display: flex;
							align-items: center;
							font-size: @vue-font-size-min;
						}
					}

					.activity-list {
						width: 100%;
						height: 150px;

						.activity-item {
							width: 100%;
							height: 30px;

							a {
								width: 100%;
								height: 100%;
								display: block;
								line-height: 30px;
								font-size: @vue-font-size-min;
								color: @vue-text-color-grey;
								overflow: hidden;
								text-overflow: ellipsis;
							}

							a:hover {
								color: @vue-text-color-active;
							}
						}
					}
				}
			}

			.middle-right {
				width: 880px;
				height: 100%;
				background: @vue-bg-color;
				box-shadow: 0px 0px 10px @vue-border-color;
				padding: 10px;
				box-sizing: border-box;

				.about-title {
					width: 100%;
					height: 40px;

					.about-word {
						width: 150px;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						color: @vue-text-color-inverse;
						background: @vue-color-primary;
					}
				}

				.about-content {
					width: 100%;
					height: 210px;
					line-height: 30px;
					color: @vue-text-color-grey;
					font-size: @vue-font-size-min + 2;
					margin: 15px 0px;
					box-sizing: border-box;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}

		.vip {
			width: 100%;
			background: @vue-bg-color;
			box-shadow: 0px 0px 10px @vue-border-color;
			margin-top: @vue-spacing-col-lg;
			padding: 10px;
			box-sizing: border-box;

			.vip-title {
				width: 100%;
				height: 40px;
				display: flex;
				justify-content: space-between;

				.vip-word {
					width: 150px;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: @vue-font-size-min + 2;
					color: @vue-text-color-inverse;
					background: @vue-color-primary;
				}

				.vip-more {
					height: 100%;
					display: flex;
					align-items: center;

					a {
						font-size: @vue-font-size-min+2;
					}
				}
			}
		}
	}
</style>