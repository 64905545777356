import axios from 'axios'
// import qs from 'qs'
import pubFunction from '../public/index.js'
import {
	Notification
} from 'element-ui'
import store from '../store/index.js'

// const requestUrl = pubFunction.requestUrl
const requestUrl = 'http://106.13.90.164:8201/'

// 需要token验证的接口
const tokenApi = [
	'/userInfo',
	'/addOrUpdateMember',
	'/deleteMember',
	'/findMemberById',
	'/findMemberList',
	'/addOrUpdateJournalism',
	'/deleteJournalism',
	'/findJournalismById',
	'/findJournalismList',
	'/addOrUpdateSystemUser',
	'/deleteSystemUser',
	'/findSystemUserById',
	'/findSystemUserList',
	'/addOrUpdateDepartment',
	'/deleteDepartment',
	'/findDepartmentById',
	'/findDepartmentList',
	'/getAllType',
	'/getAllDept',
	'/getAllRole',
	'/getMeberRange',
	'/getMeberLevel',
	'/exportMeber',
	'/addOrUpdateRotation',
	'/deleteRotation',
	'/findRotationById',
	'/findRotationList'
]
// 非必须token验证的接口
const loginApi = [

]
// 调用上传的接口
const uploadApi = [
	'/uploadFile'
]
// axios.defaults.headers.common["token"] = token
// 本地token验证
const doRequest = (api, data, type, callback) => {
	let token = pubFunction.getStorage('token')
	// 必需token验证
	if (tokenApi.indexOf(api) >= 0) {
		if (!token) {
			disposeNologin()
			return false
		} else {
			axios.defaults.headers.common["Authtoken"] = token
		}
	}
	// 非必须token验证
	if (loginApi.indexOf(api) >= 0) {
		axios.defaults.headers.common["Authtoken"] = token
	}
	let url = requestUrl + api
	if (uploadApi.indexOf(api) >= 0) {
		axios.defaults.headers.common["Authtoken"] = token
		doUpload(url, data, callback)
		return
	}
	sendPost(url, data, type, callback)
}
// 统一处理登录失效方法
const disposeNologin = () => {
	resetHeader()
	Notification.error({
		title: '发生错误',
		message: '当前登录状态已失效，请您重新登录。',
		duration: 2000,
		onClose: () => {
			store.commit('logout')
		}
	})
}
const resetHeader = () => {
	delete axios.defaults.headers.common['Authtoken']
}
const disposeError = (msg) => {
	Notification.error({
		title: '发生错误',
		message: msg,
		duration: 2000
	})
}
// 统一请求方法
const sendPost = (url, data, type, callback) => {
	if (type == 'get') {
		axios.get(url, {
			params: data
		}, {
			headers: {
				'Content-Type': 'application/json'
			}
		}).then((res) => {
			if (res.data.code == 203) {
				disposeNologin()
				return
			} else if (res.data.code != 200) {
				disposeError(res.data.msg)
			}
			callback(res.data)
		}).catch((err) => {})
	} else {
		// data = qs.stringify(data)
		axios.post(url, data, {
			headers: {
				'Content-Type': 'application/json'
			}
		}).then((res) => {
			if (res.data.code == 203) {
				disposeNologin()
				return
			} else if (res.data.code != 200) {
				disposeError(res.data.msg)
			}
			callback(res.data)
		}).catch((err) => {})
	}
}
const doUpload = (url, data, callback) => {
	axios.post(url, data, {
		headers: {
			'Content-Type': 'application/json'
		}
	}).then((res) => {
		if (res.data.code == 203) {
			disposeNologin()
			return
		} else if (res.data.code != 200) {
			disposeError(res.data.msg)
		}
		callback(res.data)
	}).catch((err) => {})
}

// 前端接口
// 轮播图
const getBanner = (data, callback) => doRequest('/webApi/findRotationList', data, 'post', callback)
// 新闻详情
const getNewsDetail = (data, callback) => doRequest('/webApi/getJournalismById', data, 'get', callback)
// 新闻列表 
const getNewsList = (data, callback) => doRequest('/webApi/getJournalismListByType', data, 'post', callback)
// 新闻类型
const getCategory = (data, callback) => doRequest('/webApi/getAllType', data, 'get', callback)
// 会员列表
const getVipList = (data, callback) => doRequest('/webApi/getMemberList', data, 'post', callback)
// 区块链公示
const getBlockChain = (data, callback) => doRequest('/webApi/queryTransactionList', data, 'get', callback)


// 后台管理接口
// 上传图片
const upload = (data, callback) => doRequest('/uploadFile', data, 'post', callback)

// 登录
const login = (data, callback) => doRequest('/login', data, 'post', callback)

// 获取用户信息
const getUserInfo = (data, callback) => doRequest('/userInfo', data, 'get', callback)
// 新增或修改会员
const editVip = (data, callback) => doRequest('/addOrUpdateMember', data, 'post', callback)
// 删除会员
const deleteVip = (data, callback) => doRequest('/deleteMember', data, 'get', callback)
// 会员详情
const vipInfo = (data, callback) => doRequest('/findMemberById', data, 'get', callback)
// 会员列表
const vipList = (data, callback) => doRequest('/findMemberList', data, 'post', callback)
// 会员范畴
const vipRange = (data, callback) => doRequest('/getMeberRange', data, 'get', callback)
// 会员等级
const vipLevel = (data, callback) => doRequest('/getMeberLevel', data, 'get', callback)
// 导出会员
const exportVip = (data, callback) => doRequest('/exportMeber', data, 'get', callback)

// 新增或修改新闻
const editNews = (data, callback) => doRequest('/addOrUpdateJournalism', data, 'post', callback)
// 删除新闻
const deleteNews = (data, callback) => doRequest('/deleteJournalism', data, 'get', callback)
// 新闻详情
const newsInfo = (data, callback) => doRequest('/findJournalismById', data, 'get', callback)
// 新闻列表
const newsList = (data, callback) => doRequest('/findJournalismList', data, 'post', callback)
// 新闻分类
const newsCategory = (data, callback) => doRequest('/getAllType', data, 'get', callback)

// 新增或修改轮播图
const editBanner = (data, callback) => doRequest('/addOrUpdateRotation', data, 'post', callback)
// 删除轮播图
const deleteBanner = (data, callback) => doRequest('/deleteRotation', data, 'get', callback)
// 轮播图详情
const bannerInfo = (data, callback) => doRequest('/findRotationById', data, 'get', callback)
// 轮播图列表
const bannerList = (data, callback) => doRequest('/findRotationList', data, 'post', callback)

// 用户管理
const editUser = (data, callback) => doRequest('/addOrUpdateSystemUser', data, 'post', callback)
// 删除用户
const deleteUser = (data, callback) => doRequest('/deleteSystemUser', data, 'get', callback)
// 用户详情
const userInfo = (data, callback) => doRequest('/findSystemUserById', data, 'get', callback)
// 用户列表
const userList = (data, callback) => doRequest('/findSystemUserList', data, 'post', callback)
// 获取用户部门
const userDepartment = (data, callback) => doRequest('/getAllDept', data, 'get', callback)
// 获取用户角色
const userRole = (data, callback) => doRequest('/getAllRole', data, 'get', callback)

// 部门管理
const editDepartment = (data, callback) => doRequest('/addOrUpdateDepartment', data, 'post', callback)
// 删除部门
const deleteDepartment = (data, callback) => doRequest('/deleteDepartment', data, 'get', callback)
// 部门详情
const departmentInfo = (data, callback) => doRequest('/findDepartmentById', data, 'get', callback)
// 部门列表
const departmentList = (data, callback) => doRequest('/findDepartmentList', data, 'post', callback)


export default {
	requestUrl,
	getBanner,
	getNewsDetail,
	getNewsList,
	getCategory,
	getVipList,
	getBlockChain,
	login,
	getUserInfo,
	editVip,
	deleteVip,
	vipInfo,
	vipList,
	vipRange,
	vipLevel,
	exportVip,
	upload,
	editNews,
	deleteNews,
	newsInfo,
	newsList,
	newsCategory,
	editBanner,
	deleteBanner,
	bannerInfo,
	bannerList,
	editUser,
	deleteUser,
	userInfo,
	userList,
	userDepartment,
	userRole,
	editDepartment,
	deleteDepartment,
	departmentInfo,
	departmentList
}