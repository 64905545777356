<template>
	<div class="basic image">
		<div class="block">
			<div class="block-title">
				文章信息
			</div>
			<div class="block-area">
				<div class="line">
					<div class="key">
						<span>*</span>
						图片链接：
					</div>
					<div class="value">
						<el-input v-model="link" placeholder="请输入图片链接"></el-input>
					</div>
				</div>
			</div>
		</div>
		<div class="block">
			<div class="block-title">
				文章内容
			</div>
			<div class="block-area">
				<div class="upload-image">
					<div class="upload-key">
						轮播图片：
					</div>
					<div class="upload-value">
						<el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false"
							:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
							<img v-if="imageUrl" :src="imageUrl" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</div>
				</div>
				<div class="upload-hint">
					建议尺寸：1920*350 像素
				</div>
			</div>
		</div>
		<div class="submit">
			<el-button type="primary" @click="saveInfo">保存</el-button>
			<el-button @click="reset">重置</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'image',
		data() {
			return {
				link:'',
				imageUrl:'',
				imageName:'',
				uploadUrl:''
			};
		},
		methods: {
			handleAvatarSuccess(res) {
				if (res.code == 200) {
					this.imageUrl = res.data.fileUrl
					this.imageName = res.data.fileName
				}
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg' || 'image/png';
				const isLt10M = file.size / 1024 / 1024 < 10;
				if (!isJPG) {
					this.$message.error('上传图片只能是 JPG、JPEG、JIFI、PNG 格式!');
				}
				if (!isLt10M) {
					this.$message.error('上传头像图片大小不能超过 10MB!');
				}
				return isJPG && isLt10M;
			},
			fetchData() {
				this.$api.bannerInfo({
					id: this.id
				}, res => {
					if (res.code == 200) {
						this.imageUrl = res.data.rotationImgUrl
						this.imageName = res.data.rotationImg
						this.link = res.data.rotationUrl
					}
				})
			},
			saveInfo() {
				this.$api.editBanner({
					rotationImg: this.imageName,
					rotationUrl: this.link,
					id: this.id,
				}, res => {
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: (this.id == '' ? '新增' : '编辑') + '轮播图成功！',
							duration: 1500,
							onClose: () => {
								this.$router.back()
							}
						});
					}
				})
			},
			reset() {
				this.imageUrl = ''
				this.link = ''
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
			this.id = this.$route.query.id
			this.uploadUrl = this.$api.requestUrl + '/uploadFile'
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
			if (this.id != '') {
				this.fetchData()
			}
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	.block {
		width: 100%;
	
		.block-title {
			width: 100%;
			height: 50px;
			background: @vue-bg-color-grey;
			padding: 0px @vue-spacing-row-base;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			border-left: 4px solid @vue-border-color-active;
		}
	
		.block-area {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 0px @vue-spacing-row-base;
			box-sizing: border-box;
	
			.line {
				width: 50%;
				height: 60px;
				display: flex;
	
				.key {
					width: 120px;
					height: 100%;
					display: flex;
					align-items: center;
					font-size: @vue-font-size-min + 2;
	
					span {
						color: @vue-text-color-red;
						font-size: @vue-font-size-base;
					}
				}
	
				.value {
					width: calc(100% - 150px);
					height: 100%;
					display: flex;
					align-items: center;
	
					.el-input,
					.el-cascader {
						width: 350px;
						margin-right: @vue-spacing-row-base;
					}
				}
			}
			.upload-image {
				width: 100%;
				display: flex;
				justify-content: space-between;
				margin-top: @vue-spacing-col-base;
				
				.upload-key {
					width: 120px;
					height: 100%;
				}
	
				.upload-value {
					width: calc(100% - 100px);
					height: 100%;
	
					.avatar-uploader {
						width: 1200px;
						border: 1px dashed #d9d9d9;
	
						.el-upload {
							border: 1px dashed #d9d9d9;
							border-radius: 6px;
							cursor: pointer;
							position: relative;
							overflow: hidden;
						}
	
						.el-upload:hover {
							border-color: #409EFF;
						}
					}
	
					.avatar-uploader-icon {
						font-size: 28px;
						color: #8c939d;
						width: 1200px;
						height: 178px;
						line-height: 178px;
						text-align: center;
					}
	
					.avatar {
						width: 1200px;
						height: 178px;
						display: block;
					}
				}
			}
			.upload-hint{
				width: 100%;
				height: 60px;
				display: flex;
				align-items: center;
				font-size: @vue-font-size-sm;
				color: @vue-text-color-red;
				padding-left: 120px;
				box-sizing: border-box;
			}
		}
	}
	
	.submit {
		width: 100%;
		margin-top: @vue-spacing-col-lg*2;
		margin-bottom: @vue-spacing-col-lg*2;
		display: flex;
		justify-content: center;
	
		.el-button {
			width: 200px;
		}
	
		.el-button:last-child {
			margin-left: @vue-spacing-row-lg*2;
		}
	}
</style>
