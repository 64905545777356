<template>
	<div class="mobile">
		<!-- <div class="mobile-box">
			<div class="post-item">
				<img src="../../assets/poster-1.png" alt="">
			</div>
			<div class="post-item">
				<img src="../../assets/poster-2.png" alt="">
			</div>
			<div class="post-item">
				<img src="../../assets/poster-3.png" alt="">
			</div>
			<div class="post-qrcode">
				<img src="../../assets/mobile-qrcode.png" alt="">
				<span>报名参与请扫描二维码</span>
			</div>
		</div> -->
		<div class="poster">
			<div class="poster-box">
				<img src="../../assets/poster.jpg" alt="">
				<button @click="flag = true">打开操作菜单</button>
			</div>
			<div class="menu" v-show="flag">
				<div class="menu-close" @click="flag = false"></div>
				<div class="menu-bottom">
					<div class="menu-item" id="copyText" data-clipboard-text="18237174131" data-clipboard-action="copy"
						data-clipboard-target="#copyText" @click="copyText">
						复制手机号
					</div>
					<div class="menu-item">
						<a href="tel:18237174131">拨打电话</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Clipboard from 'clipboard'
	export default {
		name: 'mobile',
		data() {
			return {
				flag: false
			};
		},
		methods: {
			copyText() {
				let clipboard = new Clipboard("#copyText");
				let that = this
				clipboard.on("success", function(e) {
					alert("复制成功");
					e.clearSelection();
					that.flag = false
				});
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	.mobile {
		width: 100%;

		.poster {
			max-width: 750px;
			margin: 0 auto;
			position: relative;

			.poster-box {
				width: 100%;
			}

			img {
				width: 100%;
				display: block;
			}

			button {
				width: 65%;
				height: 2%;
				background: cyan;
				position: absolute;
				z-index: 1;
				bottom: 3%;
				right: 0px;
				opacity: 0;
			}
		}

		.menu {
			width: 100%;
			height: 100%;
			position: absolute;
			bottom: 0px;
			left: 0px;
			background: rgba(0, 0, 0, 0.5);
			display: flex;
			justify-content: center;
			align-items: flex-end;
			flex-wrap: wrap;
			z-index: 9;

			.menu-close {
				width: 100%;
				height: calc(100% - 120px);
			}

			.menu-bottom {
				width: 100%;
				height: 120px;
				background: @vue-bg-color;
				padding: 0px 15px 20px 15px;
				box-sizing: border-box;

				.menu-item,
				a {
					width: 100%;
					height: 50px;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.menu-item {
					border-bottom: 1px solid @vue-border-color;
					box-sizing: border-box;
					color: @vue-text-color;
				}

				a {
					color: @vue-text-color;
				}
			}
		}

		// .mobile-box {
		// 	max-width: 750px;
		// 	margin: 0 auto;
		// 	position: relative;

		// 	.post-item {
		// 		width: 100%;

		// 		img {
		// 			width: 100%;
		// 			display: block;
		// 		}
		// 	}

		// 	.post-qrcode {
		// 		width: 80%;
		// 		height: 6%;
		// 		background: linear-gradient(to right, rgba(2, 27, 240, 1), rgba(255, 255, 255, 0));
		// 		position: absolute;
		// 		bottom: 3%;
		// 		left: 10%;
		// 		display: flex;
		// 		align-items: center;

		// 		img {
		// 			height: 100%;
		// 		}

		// 		span {
		// 			color: #FFFFFF;
		// 			font-size: 18px;
		// 			font-weight: bold;
		// 			margin-left: 12px;
		// 			box-sizing: border-box;
		// 		}
		// 	}
		// }
	}
</style>