import Vue from 'vue'
import Vuex from 'vuex'
import pubFunction from '../public/index.js'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		hasLogin: false,
		token: '',
		userInfo: {}
	},
	mutations: {
		login(state, token) {
			state.hasLogin = true;
			state.token = token
			pubFunction.setStorage('token', token)
		},
		logout(state) {
			state.hasLogin = false;
			state.token = ''
			localStorage.clear()
			pubFunction.routerToLogin()
		},
		saveUserinfo(state, userInfo) {
			state.userInfo = userInfo;
			pubFunction.setStorage('userInfo', JSON.stringify(userInfo))
		}
	},
	actions: {},
	modules: {}
})
