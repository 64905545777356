import Vue from 'vue'
import VueRouter from 'vue-router'
// 前端
import layout from '../views/layout/layout.vue';
import index from '../views/page/index/index.vue';
import concat from '../views/page/concat/concat.vue';
import dynamic from '../views/page/dynamic/dynamic.vue';
import activity from '../views/page/activity/activity.vue';
import list from '../views/page/list/list.vue';
import detail from '../views/page/detail/detail.vue';
import blockchain from '../views/page/blockchain/blockchain'
import mobile from '../views/mobile/mobile'
import poster from '../views/mobile/poster'
// 管理端
import login from '../management/login/login.vue';
import admin from '../management/layout/layout.vue';
import user from '../management/page/user/user.vue';
// import department from '../management/page/department/department.vue';
import article from '../management/page/article/article.vue';
import slide from '../management/page/slide/slide.vue';
import vip from '../management/page/vip/vip.vue';
import newly from '../management/page/newly/newly.vue';
import image from '../management/page/image/image.vue';
import edit from '../management/page/edit/edit.vue';
import add from '../management/page/add/add.vue';
// import setting from '../management/page/setting/setting.vue';
// 404页面
import NotFound from '../views/notFound/notFound.vue';

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'layout',
		component: layout,
		redirect: '/index',
		children: [{
			path: '/index',
			name: 'index',
			component: index,
			meta: {
				title: '中国信息协会法律分会-首页'
			}
		}, {
			path: '/concat',
			name: 'concat',
			component: concat,
			meta: {
				title: '中国信息协会法律分会-联系我们'
			}
		}, {
			path: '/dynamic',
			name: 'dynamic',
			component: dynamic,
			meta: {
				title: '中国信息协会法律分会-动态'
			}
		}, {
			path: '/activity',
			name: 'activity',
			component: activity,
			meta: {
				title: '中国信息协会法律分会-活动'
			}
		}, {
			path: '/list',
			name: 'list',
			component: list,
			meta: {
				title: '中国信息协会法律分会-会员列表'
			}
		}, {
			path: '/detail',
			name: 'detail',
			component: detail,
			meta: {
				title: '中国信息协会法律分会-详情'
			}
		}, {
			path: '/blockchain',
			name: 'blockchain',
			component: blockchain,
			meta: {
				title: '中国信息协会法律分会-区块链公示'
			}
		}]
	},
	{
		path: '/JZ',
		name: 'JZ',
		component: mobile,
		redirect: '/JZ/ChatGPT',
		children: [{
			path: '/JZ/ChatGPT',
			name: 'ChatGPT',
			component: poster,
			meta: {
				title: '中国信息协会法律分会-讲座栏目',
				name: '讲座栏目'
			}
		}]
	},
	{
		path: '/login',
		name: 'login',
		component: login,
		meta: {
			title: '中国信息协会法律分会-管理后台-登录',
			name: '登录'
		}
	},
	{
		path: '/admin',
		name: 'admin',
		component: admin,
		redirect: '/user',
		children: [{
				path: '/user',
				name: 'user',
				component: user,
				meta: {
					title: '中国信息协会法律分会-管理后台-管理员',
					name: '管理员'
				}
			},
			{
				path: '/add',
				name: 'add',
				component: add,
				meta: {
					title: '中国信息协会法律分会-管理后台-编辑管理员',
					name: '编辑管理员'
				}
			},
			// {
			// 	path: '/department',
			// 	name: 'department',
			// 	component: department,
			// 	meta: {
			// 		title: '中国信息协会法律分会-管理后台-部门管理',
			// 		name: '部门管理'
			// 	}
			// },
			// {
			// 	path: '/setting',
			// 	name: 'setting',
			// 	component: setting,
			// 	meta: {
			// 		title: '中国信息协会法律分会-管理后台-编辑部门',
			// 		name: '编辑部门'
			// 	}
			// },
			{
				path: '/article',
				name: 'article',
				component: article,
				meta: {
					title: '中国信息协会法律分会-管理后台-文章管理',
					name: '文章管理'
				}
			},
			{
				path: '/newly',
				name: 'newly',
				component: newly,
				meta: {
					title: '中国信息协会法律分会-管理后台-编辑文章',
					name: '编辑文章'
				}
			},
			{
				path: '/slide',
				name: 'slide',
				component: slide,
				meta: {
					title: '中国信息协会法律分会-管理后台-轮播图管理',
					name: '轮播图管理'
				}
			},
			{
				path: '/image',
				name: 'image',
				component: image,
				meta: {
					title: '中国信息协会法律分会-管理后台-编辑轮播图',
					name: '编辑轮播图'
				}
			},
			{
				path: '/vip',
				name: 'vip',
				component: vip,
				meta: {
					title: '中国信息协会法律分会-管理后台-会员管理',
					name: '会员管理'
				}
			},
			{
				path: '/edit',
				name: 'edit',
				component: edit,
				meta: {
					title: '中国信息协会法律分会-管理后台-编辑会员',
					name: '编辑会员'
				}
			}
		]
	},
	{
		path: '/notFound',
		name: 'notFound',
		component: NotFound,
		meta: {
			title: '中国信息协会法律分会-找不到页面',
			name: '找不到页面'
		}
	},
	{
		path: '/:pathMatch(.*)',
		redirect: '/notFound'
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router