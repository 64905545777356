<template>
	<div class="layout">
		<Header></Header>
		<Banner></Banner>
		<router-view></router-view>
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from '../../components/header.vue';
	import Footer from '../../components/footer.vue';
	import Banner from '../../components/banner.vue';
	export default {
		name: 'layout',
		components:{
			Header,
			Banner,
			Footer
		},
		data() {
			return {};
		},
		methods: {},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped"></style>
