<template>
	<div class="login" @keydown.enter="keydownMethod">
		<div class="left">
			<div class="left-in">
				<div class="logo">
					<img src="../../assets/logo@2x.png" alt="">
				</div>
				<div class="welcome">
					<div class="big_cn">
						欢迎登录
					</div>
					<div class="big_en">
						Entry Management System
					</div>
					<div class="copyright">
						Copyright © 2021 中国信息协会法律分会
					</div>
				</div>
				<div class="point">
					<img src="../../assets/pic_login_point@2x.png" alt="">
				</div>
			</div>
		</div>
		<div class="right">
			<div class="login-box">
				<div class="login-title">
					中国信息协会法律分会
				</div>
				<div class="login-hint">
					
				</div>
				<div class="input">
					<div class="key">
						用户名
					</div>
					<div class="value">
						<el-input type="text" placeholder="请输入您的用户名" v-model="username" ></el-input>
					</div>
				</div>
				<div class="input">
					<div class="key">
						密码
					</div>
					<div class="value">
						<el-input type="password" placeholder="请输入对应的密码" v-model="password" ></el-input>
					</div>
				</div>
				<div class="login-btn">
					<el-button type="primary" @click="doLogin" :loading="loading">
						登录
					</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapMutations
	} from 'vuex'
	export default {
		name: 'login',
		data() {
			return {
				username: '',
				password: '',
				loading: false
			};
		},
		methods: {
			...mapMutations(['login']),
			doLogin() {
				this.loading = true
				this.$api.login({
					'username': this.username,
					'password': this.password
				}, res => {
					this.loading = false
					if(res.code==200){
						this.login(res.data.token)
						this.$notify({
							title: '成功',
							message: '登录成功，即将为您跳转',
							type: 'success',
							duration: 1500,
							onClose:()=>{
								this.$router.push({
									path:'/admin'
								})
							}
						})
					}
				})
			},
			keydownMethod(){
				this.doLogin()
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	.login {
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0px;
		top: 0px;
		z-index: 1;
		background: #f1f5f8;
		display: flex;
		justify-content: space-between;
	
		.left,
		.right {
			height: 100%;
		}
	
		.left {
			width: 70%;
			padding: 175px 120px;
			box-sizing: border-box;
	
			.left-in {
				width: 100%;
				height: 100%;
				position: relative;
	
				.logo {
					width: 400px;
					height: 103px;
					img{
						width: 100%;
						height: 100%;
						display: block;
					}
				}
	
				.welcome {
					width: 400px;
					position: absolute;
					left: 0px;
					bottom: 0px;
					z-index: 2;
	
					.big_cn {
						width: 100%;
						height: 60px;
						font-size: @vue-font-size-max;
					}
	
					.big_en {
						width: 100%;
						line-height: 50px;
						font-size: @vue-font-size-base;
						font-weight: 300;
					}
	
					.copyright {
						width: 100%;
						height: 30px;
						display: flex;
						align-items: center;
						font-size: @vue-font-size-sm - 2;
						color: @vue-text-color-grey;
						font-weight: 300;
					}
				}
	
				.point {
					width: 54px;
					height: 38px;
					position: absolute;
					right: 0px;
					bottom: 20px;
					z-index: 2;
	
					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	
		.right {
			width: 30%;
			background: url(../../assets/pic_login_blue@2x.png) no-repeat;
			background-size: 100% 100%;
			display: flex;
			align-items: center;
	
			.login-box {
				width: 350px;
				height: 420px;
				position: relative;
				z-index: 2;
				background: @vue-bg-color;
				box-shadow: 0px 0px 10px #D3D3D3;
				border-radius: @vue-border-radius-base;
				left: -100px;
				padding: 50px 40px;
				box-sizing: border-box;
	
				.login-title {
					width: 100%;
					height: 50px;
					font-size: @vue-font-size-base+2;
					color: @vue-text-color;
				}
	
				.login-hint {
					width: 100%;
					height: 24px;
					font-size: @vue-font-size-sm;
					color: @vue-text-color-active;
				}
	
				.input {
					width: 100%;
					height: 70px;
					margin-bottom: @vue-spacing-row-sm;
	
					.key {
						width: 100%;
						height: 30px;
						display: flex;
						align-items: center;
						font-size: @vue-font-size-sm - 2;
						color: @vue-text-color-grey;
					}
	
					.value {
						width: 100%;
						height: 40px;
	
						.el-input {
							width: 100%;
							background: @vue-bg-color-grey;
						}
					}
				}
	
				.login-btn {
					width: 100%;
					height: 40px;
					margin-top: @vue-spacing-row-lg;
	
					.el-button {
						width: 100%;
						box-shadow: 0px 0px 10px @vue-color-primary;
					}
				}
			}
		}
	}
</style>
