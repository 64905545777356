import Vue from 'vue'
import App from './App.vue'
// router与store
import router from './router'
import store from './store'

// 引入element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 引入百度地图vue
import BaiduMap from 'vue-baidu-map';

// 引入api
import api from './api/index.js'
import pub from './public/index.js'
import ver from './verification/index.js'

Vue.prototype.$api = api
Vue.prototype.$pub = pub
Vue.prototype.$ver = ver

Vue.use(ElementUI)
Vue.use(BaiduMap,{
	ak:'m05gIG2RyGl81OKWWtdzQrHovb3AW0sX'
})

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
