import { render, staticRenderFns } from "./blockchain.vue?vue&type=template&id=f4ab7246&scoped=true&"
import script from "./blockchain.vue?vue&type=script&lang=js&"
export * from "./blockchain.vue?vue&type=script&lang=js&"
import style0 from "./blockchain.vue?vue&type=style&index=0&id=f4ab7246&lang=less&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4ab7246",
  null
  
)

export default component.exports