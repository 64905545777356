<template>
	<div class="blockchain">
		<div class="fixation">
			<div class="public-title">
				<div class="zh">
					最新保全区块公示
				</div>
				<div class="en">
					LATEST BLOCK PUBLICITY | 证据存证记录
				</div>
			</div>
			<div class="table">
				<!-- 接口返回的字段如果不匹配的话，就把prop属性的值改为接口返回的字段 -->
				<el-table :data="tableData" stripe style="width: 100%" border
					:header-cell-style="{background:'#eaf0fa'}">
					<el-table-column prop="close_time" label="存证时间" width="300" align="center">
					</el-table-column>
					<el-table-column prop="hash" label="区块链ID" width="300" align="center">
					</el-table-column>
					<el-table-column prop="ledger_seq" label="区块链高度" width="300" align="center">
					</el-table-column>
					<el-table-column prop="file_hash" label="证据哈希" align="center">
					</el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		name: 'blockchain',
		data() {
			return {
				pageSize: 12,
				currentPage: 1,
				tableData: []
			};
		},
		methods: {
			fetchData() {
				this.$api.getBlockChain({},res=>{
					if(res.code == 200){
						this.tableData = res.data
					}
				})
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
			this.fetchData()
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	.blockchain {
		padding: @vue-spacing-col-lg 0px;
		box-sizing: border-box;
	}

	.public-title {
		width: 100%;
		height: 160px;
		display: flex;
		align-items: center;
		align-content: center;
		flex-wrap: wrap;

		.zh,
		.en {
			width: 100%;
			text-align: center;
		}

		.zh {
			color: #333333;
			font-size: 24px;
			font-weight: bold;
		}

		.en {
			color: #999999;
			font-size: 14px;
			margin-top: 10px;
		}
	}
</style>