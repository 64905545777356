import { render, staticRenderFns } from "./poster.vue?vue&type=template&id=3df5a521&scoped=true&"
import script from "./poster.vue?vue&type=script&lang=js&"
export * from "./poster.vue?vue&type=script&lang=js&"
import style0 from "./poster.vue?vue&type=style&index=0&id=3df5a521&lang=less&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3df5a521",
  null
  
)

export default component.exports