<template>
	<div class="activity">
		<div class="fixation">
			<div class="navigation">
				<div class="navigation-item navigation-active">
					分会活动
					<span>>></span>
				</div>
			</div>
			<div class="list">
				<div class="list-title">
					分会活动
				</div>
				<div class="flow">
					<ul>
						<li v-for="(item,index) in flow" :key="index">
							<router-link :to="{path:'/detail',query:{id:item.id}}">
								<span>
									<i class="el-icon-s-order"></i>
									{{item.articleTitle.length>44?item.articleTitle.substring(0,45)+'...':item.articleTitle}}
								</span>
								<span>{{item.createAt}}</span>
							</router-link>
						</li>
					</ul>
				</div>
				<div class="page">
					<el-pagination @current-change="handleCurrentChange" :current-page="currentPage4" :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'activity',
		data() {
			return {
				flow: [],
				currentPage4:1,
				pageSize:15,
				total:0
			};
		},
		methods: {
			handleCurrentChange(e){
				this.currentPage4 = e
				this.fetchData()
			},
			fetchData(){
				this.$api.getNewsList({
					articleType:this.id,
					pageNum:this.currentPage4,
					pageSize:this.pageSize
				},res=>{
					if(res.code==200){
						this.flow = res.data.list
					}
				})
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
			this.id = this.$route.query.id
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
			this.fetchData()
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	.activity {
		width: 100%;
		padding: @vue-spacing-col-lg 0px;
		background: @vue-bg-color-grey;
	}

	.fixation {
		display: flex;
		justify-content: space-between;

		.navigation {
			width: 250px;

			.navigation-item {
				width: 100%;
				height: 50px;
				border-left: 4px solid @vue-border-color-active;
				box-sizing: border-box;
				padding: 0px 10px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: @vue-spacing-col-base;
				background: @vue-bg-color;
				box-shadow: 0px 0px 10px @vue-border-color;
			}

			.navigation-active {
				color: @vue-text-color-active;
			}
		}

		.list {
			width: 920px;
			box-shadow: 0px 0px 10px @vue-border-color;
			background: @vue-bg-color;
			padding: 0px @vue-spacing-row-base;
			box-sizing: border-box;

			.list-title {
				width: 100%;
				height: 70px;
				border-bottom: 1px solid @vue-border-color;
				display: flex;
				align-items: center;
				font-size: @vue-font-size-base;
				font-weight: bold;
				color: @vue-text-color-active;
			}

			.flow {
				width: 100%;

				ul {
					width: 100%;

					li {
						width: 100%;
						height: 50px;
						border-bottom: 1px dashed @vue-border-color;
						box-sizing: border-box;

						a {
							width: 100%;
							height: 100%;
							display: flex;
							justify-content: space-between;
							align-items: center;
							color: @vue-text-color-grey;

							i {
								color: @vue-text-color-active;
							}
						}
					}
				}
			}
			.page{
				width: 100%;
				height: 70px;
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
		}
	}
</style>
