<template>
	<div class="basic edit">
		<div class="block">
			<div class="block-title">
				会员信息
			</div>
			<div class="block-area">
				<div class="line">
					<div class="key">
						<span>*</span>
						企业名称：
					</div>
					<div class="value">
						<el-input v-model="company" placeholder="请输入企业名称" clearable></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						<span>*</span>
						证书编号：
					</div>
					<div class="value">
						<el-input v-model="code" placeholder="请输入证书编号" clearable></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						<span>*</span>
						会员等级：
					</div>
					<div class="value">
						<el-select v-model="levelVal" placeholder="请选择会员等级">
							<el-option v-for="(item,index) in level" :key="index" :label="item.label"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="line">
					<div class="key">
						会员密码：
					</div>
					<div class="value">
						<el-input type="password" v-model="password" placeholder="请输入会员密码" clearable></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						<span>*</span>
						所属部门：
					</div>
					<div class="value">
						<el-cascader v-model="departmentVal" :options="department" :props="departProps" placeholder="请选择部门" collapse-tags clearable></el-cascader>
					</div>
				</div>
				<div class="line">
					<div class="key">
						<span>*</span>
						会员范畴：
					</div>
					<div class="value">
						<el-cascader v-model="rangeVal" :options="range" :props="props" placeholder="请选择会员范畴"
							collapse-tags clearable></el-cascader>
					</div>
				</div>
				<div class="line">
					<div class="key">
						<span>*</span>
						企业地址：
					</div>
					<div class="value">
						<el-input v-model="address" placeholder="请输入企业地址" clearable></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						联系电话：
					</div>
					<div class="value">
						<el-input v-model="phone" placeholder="请输入联系电话" clearable></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						法定代表人：
					</div>
					<div class="value">
						<el-input v-model="leader" placeholder="请输入法定代表人" clearable></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						注册资本：
					</div>
					<div class="value">
						<el-input type="number" v-model="money" placeholder="请输入注册资本(万元)" clearable></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						联系人：
					</div>
					<div class="value">
						<el-input v-model="concat" placeholder="请输入联系人" clearable></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						职务：
					</div>
					<div class="value">
						<el-input v-model="job" placeholder="请输入职务" clearable></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						手机：
					</div>
					<div class="value">
						<el-input type="phone" v-model="telphone" placeholder="请输入手机号" clearable></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						邮箱：
					</div>
					<div class="value">
						<el-input type="email" v-model="email" placeholder="请输入邮箱" clearable></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						注册时间：
					</div>
					<div class="value">
						<el-date-picker v-model="registerTime" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="请选择注册时间">
						</el-date-picker>
					</div>
				</div>
				<div class="line">
					<div class="key">
						企业类别：
					</div>
					<div class="value">
						<el-input v-model="companyType" placeholder="请输入企业类别" clearable></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						所属行业：
					</div>
					<div class="value">
						<el-input v-model="industry" placeholder="请输入所属行业" clearable></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						企业性质：
					</div>
					<div class="value">
						<el-input v-model="nature" placeholder="请输入企业性质" clearable></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						营业执照注册号：
					</div>
					<div class="value">
						<el-input v-model="license" placeholder="请输入营业执照注册号" clearable></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						组织机构代码：
					</div>
					<div class="value">
						<el-input v-model="organizationCode" placeholder="请输入组织机构代码" clearable></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						主导产品：
					</div>
					<div class="value">
						<el-input v-model="product" placeholder="请输入主导产品" clearable></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						自主品牌：
					</div>
					<div class="value">
						<el-input type="email" v-model="brand" placeholder="请输入自主品牌" clearable></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						企业简介：
					</div>
					<div class="value">
						<el-input v-model="description" placeholder="请输入企业简介" clearable></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						<span>*</span>
						证书有效期：
					</div>
					<div class="value">
						<el-date-picker v-model="timeLine" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="请选择证书有效期">
						</el-date-picker>
					</div>
				</div>
			</div>
		</div>
		<div class="submit">
			<el-button type="primary" @click="saveInfo">保存</el-button>
			<el-button @click="reset">重置</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'edit',
		data() {
			return {
				id:'',
				company: '',
				code: '',
				levelVal: '',
				level: [],
				password: '',
				department: [],
				departmentVal: [],
				departProps:{
					value:'label'
				},
				rangeVal: [],
				range: [],
				props: {
					multiple: true,
					value: 'label'
				},
				address: '',
				phone: '',
				leader: '',
				money: '',
				concat: '',
				job: '',
				telphone: '',
				email: '',
				registerTime: '',
				companyType: '',
				industry: '',
				nature: '',
				license: '',
				organizationCode: '',
				product: '',
				brand: '',
				description: '',
				timeLine: ''
			};
		},
		methods: {
			checkForm(){
				if(this.company==''){
					this.$message.error('请输入账号');
					return false
				}
				if(this.code==''){
					this.$message.error('请输入证书编号');
					return false
				}
				if(this.levelVal==''){
					this.$message.error('请选择会员等级');
					return false
				}
				if(this.departmentVal.length==0){
					this.$message.error('请选择部门');
					return false
				}
				if(this.rangeVal.length==0){
					this.$message.error('请选择会员范畴');
					return false
				}
				if(this.address==''){
					this.$message.error('请输入企业地址');
					return false
				}
				if(this.timeLine==''){
					this.$message.error('请选择证书有效期');
					return false
				}
				return true
			},
			saveInfo() {
				let flag = this.checkForm()
				if(!flag){
					return
				}
				const loading = this.$loading({
					lock:true,
					text:'请稍候'
				})
				this.$api.editVip({
					enterpriseName:this.company,
					certificateNo:this.code,
					memberLevel:this.levelVal,
					enterprisePassword:this.password,
					deptId:this.departmentVal.join(','),
					category:this.rangeVal.join(','),
					enterpriseAddress:this.address,
					legalPhone:this.phone,
					legalName:this.leader,
					capital:this.money,
					contacts:this.concat,
					duties:this.job,
					contactNumber:this.telphone,
					email:this.email,
					registerAt:this.registerTime,
					enterpriseType:this.companyType,
					industry:this.industry,
					enterpriseNature:this.nature,
					businessLicense:this.license,
					organCode:this.organizationCode,
					product:this.product,
					brand:this.brand,
					enterpriseIntroduction:this.description,
					effectiveEndAt:this.timeLine,
					id:this.id
				},res=>{
					loading.close()
					if(res.code==200){
						this.$message({
							type: 'success',
							message: (this.id == '' ? '新增' : '编辑') + '会员成功！',
							duration: 1500,
							onClose: () => {
								this.$router.back()
							}
						});
					}
				})
			},
			reset() {
				this.company = ''
				this.code = ''
				this.levelVal = ''
				this.password = ''
				this.departmentVal = []
				this.rangeVal = []
				this.address = ''
				this.phone = ''
				this.leader = ''
				this.money = ''
				this.concat = ''
				this.job = ''
				this.telphone = ''
				this.email = ''
				this.registerAt = ''
				this.enterpriseType = ''
				this.industry = ''
				this.nature = ''
				this.license = ''
				this.organizationCode = ''
				this.product = ''
				this.brand = ''
				this.description = ''
				this.timeLin = ''
			},
			fetchData() {
				this.$api.vipInfo({
					id: this.id
				}, res => {
					if (res.code == 200) {
						this.company = res.data.enterpriseName
						this.code = res.data.certificateNo
						this.levelVal = res.data.memberLevel
						this.password = res.data.enterprisePassword
						this.departmentVal = res.data.deptId.split(',')
						this.rangeVal = res.data.category.split(',')
						this.address = res.data.enterpriseAddress
						this.phone = res.data.legalPhone
						this.leader = res.data.legalName
						this.money = res.data.capital
						this.concat = res.data.contacts
						this.job = res.data.duties
						this.telphone = res.data.contactNumber
						this.email = res.data.email
						this.registerTime = res.data.registerAt
						this.companyType = res.data.enterpriseType
						this.industry = res.data.industry
						this.nature = res.data.enterpriseNature
						this.license = res.data.businessLicense
						this.organizationCode = res.data.organCode
						this.product = res.data.product
						this.brand = res.data.brand
						this.description = res.data.enterpriseIntroduction
						this.timeLine = res.data.effectiveEndAt
					}
				})
			},
			fetchLevel() {
				this.$api.vipLevel({}, res => {
					if (res.code == 200) {
						this.level = res.data
					}
				})
			},
			fetchRange() {
				this.$api.vipRange({}, res => {
					if (res.code == 200) {
						this.range = res.data
					}
				})
			},
			fetchDepartment(){
				this.$api.userDepartment({},res=>{
					if(res.code==200){
						this.department = res.data
					}
				})
			},
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
			this.id = this.$route.query.id
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
			if (this.id != '') {
				this.fetchData()
			}
			this.fetchLevel()
			this.fetchRange()
			this.fetchDepartment()
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	.edit {
		overflow-y: auto;
	}

	.block {
		width: 100%;

		.block-title {
			width: 100%;
			height: 50px;
			background: @vue-bg-color-grey;
			padding: 0px @vue-spacing-row-base;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			border-left: 4px solid @vue-border-color-active;
		}

		.block-area {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 0px @vue-spacing-row-base;
			box-sizing: border-box;

			.line {
				width: 50%;
				height: 60px;
				display: flex;

				.key {
					width: 120px;
					height: 100%;
					display: flex;
					align-items: center;
					font-size: @vue-font-size-min + 2;

					span {
						color: @vue-text-color-red;
						font-size: @vue-font-size-base;
					}
				}

				.value {
					width: calc(100% - 150px);
					height: 100%;
					display: flex;
					align-items: center;

					.el-input,
					.el-select,
					.el-cascader {
						width: 350px;
						margin-right: @vue-spacing-row-base;
					}
				}
			}
		}
	}

	.submit {
		width: 100%;
		margin-top: @vue-spacing-col-lg*2;
		display: flex;
		justify-content: center;

		.el-button {
			width: 200px;
		}

		.el-button:last-child {
			margin-left: @vue-spacing-row-lg*2;
		}
	}
</style>
