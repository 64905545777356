<template>
	<div class="detail">
		<div class="fixation">
			<div class="title">
				{{article.title}}
			</div>
			<div class="time">
				{{article.time}}
			</div>
			<div class="content">
				<p v-html="article.content"></p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'detail',
		data() {
			return {
				id: '',
				article: {
					title: '',
					time: '',
					content: ''
				}
			};
		},
		methods: {
			fetchData() {
				this.$api.getNewsDetail({
					id: this.id
				}, res => {
					if (res.code == 200) {
						this.article = {
							title: res.data.articleTitle,
							time: res.data.createAt,
							content: res.data.articleContent
						}
					}
				})
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
			this.id = this.$route.query.id
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
			this.fetchData()
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	.detail {
		width: 100%;
		background: @vue-bg-color-grey;
		padding: @vue-spacing-col-lg 0px;
		box-sizing: border-box;

		.fixation {
			background: @vue-bg-color;
		}

		.title {
			width: 100%;
			padding: 20px 0px;
			line-height: 30px;
			font-size: @vue-font-size-base;
			text-align: center;
		}

		.time {
			width: 100%;
			height: 50px;
			line-height: 30px;
			font-size: @vue-font-size-sm;
			color: @vue-text-color-grey;
			text-align: center;
			border-bottom: 1px dashed @vue-border-color;
		}

		.content {
			width: 100%;
			padding: @vue-spacing-col-lg 0px;
			box-sizing: border-box;

			p {
				text-indent: 2em;
				line-height: 1.5em;
			}
		}
	}
</style>