<template>
	<div class="basic newly">
		<div class="block">
			<div class="block-title">
				文章信息
			</div>
			<div class="block-area">
				<div class="line">
					<div class="key">
						<span>*</span>
						所属栏目：
					</div>
					<div class="value">
						<el-cascader v-model="column" :props="props" :options="options" @change="handleChange">
						</el-cascader>
					</div>
				</div>
				<!-- <div class="line">
					<div class="key">
						<span>*</span>
						发布时间：
					</div>
					<div class="value">
						<el-date-picker v-model="issueTime" type="datetime" placeholder="选择发布时间">
						</el-date-picker>
					</div>
				</div> -->
				<div class="line">
					<div class="key">
						<span>*</span>
						标题：
					</div>
					<div class="value">
						<el-input v-model="title" placeholder="请输入文章标题"></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						<span>*</span>
						作者：
					</div>
					<div class="value">
						<el-input v-model="author" placeholder="请输入作者"></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						来源：
					</div>
					<div class="value">
						<el-input v-model="source" placeholder="请输入文章来源"></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						摘要：
					</div>
					<div class="value">
						<el-input v-model="description" placeholder="请输入摘要"></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						地址：
					</div>
					<div class="value">
						<el-input v-model="address" placeholder="请输入地址"></el-input>
					</div>
				</div>
			</div>
		</div>
		<div class="block">
			<div class="block-title">
				文章内容
			</div>
			<div class="block-area">
				<div class="article-content">
					<div class="article-key">
						内容：
					</div>
					<div class="article-value">
						<Tinymce v-model="richText" placeholder="请编辑您的模板..." :height="500"></Tinymce>
					</div>
				</div>
				<div class="upload-image">
					<div class="upload-key">
						封面图片：
					</div>
					<div class="upload-value">
						<el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false"
							:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
							<img v-if="imageUrl" :src="imageUrl" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</div>
				</div>
			</div>
		</div>
		<div class="submit">
			<el-button type="primary" @click="saveInfo">保存</el-button>
			<el-button @click="reset">重置</el-button>
		</div>
	</div>
</template>

<script>
	import Tinymce from '@/components/Tinymce'
	export default {
		name: 'newly',
		components: {
			Tinymce
		},
		data() {
			return {
				column: [],
				options: [],
				issueTime: '',
				title: '',
				author: '',
				source: '',
				description: '',
				address: '',
				uploadUrl: '',
				imageUrl: '',
				id: '',
				richText: '',
				props: {
					value: 'id'
				}
			};
		},
		methods: {
			handleAvatarSuccess(res) {
				if (res.code == 200) {
					this.imageUrl = res.data.fileUrl
				}
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg' || 'image/png';
				const isLt10M = file.size / 1024 / 1024 < 10;
				if (!isJPG) {
					this.$message.error('上传图片只能是 JPG、JPEG、JIFI、PNG 格式!');
				}
				if (!isLt10M) {
					this.$message.error('上传图片大小不能超过 10MB!');
				}
				return isJPG && isLt10M;
			},
			fetchData() {
				this.$api.newsInfo({
					id: this.id
				}, res => {
					if (res.code == 200) {
						console.log(res);
						let col = res.data.articleStr.split(',')
						for (let i = 0; i < col.length; i++) {
							col[i] = parseInt(col[i])
						}
						this.column = col
						this.title = res.data.articleTitle
						this.author = res.data.articleAuthor
						this.source = res.data.articleSource
						this.description = res.data.articleAbstract
						this.address = res.data.articleAddress
						this.imageUrl = res.data.articleImg
						this.richText = res.data.articleContent
					}
				})
			},
			fetchCategory() {
				this.$api.newsCategory({}, res => {
					if (res.code == 200) {
						this.options = res.data
					}
				})
			},
			saveInfo() {
				this.$api.editNews({
					articleType: this.column[1],
					articleStr: this.column.join(','),
					releaseAt: this.issueTime,
					articleTitle: this.title,
					articleAuthor: this.author,
					articleSource: this.source,
					articleAbstract: this.description,
					articleAddress: this.address,
					articleImg: this.imageUrl,
					id: this.id,
					articleContent: this.richText
				}, res => {
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: (this.id == '' ? '新增' : '编辑') + '文章成功！',
							duration: 1500,
							onClose: () => {
								this.$router.back()
							}
						});
					}
				})
			},
			handleChange() {
				console.log(this.column);
			},
			reset() {
				this.column = []
				this.issueTime = ''
				this.title = ''
				this.source = ''
				this.description = ''
				this.address = ''
				this.imageUrl = ''
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
			this.id = this.$route.query.id
			this.uploadUrl = this.$api.requestUrl + '/uploadFile'
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
			if (this.id != '') {
				this.fetchData()
			}
			this.fetchCategory()
			this.$message({
				type:'warning',
				message:'内容编辑器第一次加载需要时间，请稍候...'
			})
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	.newly {
		overflow-y: auto;
	}

	.block {
		width: 100%;

		.block-title {
			width: 100%;
			height: 50px;
			background: @vue-bg-color-grey;
			padding: 0px @vue-spacing-row-base;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			border-left: 4px solid @vue-border-color-active;
		}

		.block-area {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 0px @vue-spacing-row-base;
			box-sizing: border-box;

			.line {
				width: 50%;
				height: 60px;
				display: flex;

				.key {
					width: 120px;
					height: 100%;
					display: flex;
					align-items: center;
					font-size: @vue-font-size-min + 2;

					span {
						color: @vue-text-color-red;
						font-size: @vue-font-size-base;
					}
				}

				.value {
					width: calc(100% - 150px);
					height: 100%;
					display: flex;
					align-items: center;

					.el-input,
					.el-cascader {
						width: 350px;
						margin-right: @vue-spacing-row-base;
					}
				}
			}

			.article-content {
				width: 100%;
				height: 670px;
				margin-top: @vue-spacing-col-base;
				display: flex;
				justify-content: space-between;

				.article-key {
					width: 100px;
					height: 100%;
				}

				.article-value {
					width: calc(100% - 100px);
					height: 100%;
				}
			}

			.upload-image {
				width: 100%;
				display: flex;
				justify-content: space-between;

				.upload-key {
					width: 100px;
					height: 100%;
				}

				.upload-value {
					width: calc(100% - 100px);
					height: 100%;

					.avatar-uploader {
						width: 178px;
						border: 1px dashed #d9d9d9;

						.el-upload {
							border: 1px dashed #d9d9d9;
							border-radius: 6px;
							cursor: pointer;
							position: relative;
							overflow: hidden;
						}

						.el-upload:hover {
							border-color: #409EFF;
						}
					}

					.avatar-uploader-icon {
						font-size: 28px;
						color: #8c939d;
						width: 178px;
						height: 178px;
						line-height: 178px;
						text-align: center;
					}

					.avatar {
						width: 178px;
						height: 178px;
						display: block;
					}
				}
			}
		}
	}

	.submit {
		width: 100%;
		margin-top: @vue-spacing-col-lg*2;
		margin-bottom: @vue-spacing-col-lg*2;
		display: flex;
		justify-content: center;

		.el-button {
			width: 200px;
		}

		.el-button:last-child {
			margin-left: @vue-spacing-row-lg*2;
		}
	}
</style>
