<template>
	<div class="basic vip">
		<div class="bread">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/user' }">会员管理</el-breadcrumb-item>
				<el-breadcrumb-item>会员列表</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="filtrate">
			<div class="item">
				<div class="key">企业名称：</div>
				<div class="value">
					<el-input v-model="company" placeholder="请输入企业名称" @input="fetchData()" clearable></el-input>
				</div>
			</div>
			<div class="item">
				<div class="key">会员范畴：</div>
				<div class="value">
					<el-cascader v-model="rangeVal" :options="range" :props="props" placeholder="请选择会员范畴"
						@change="fetchData()" collapse-tags clearable></el-cascader>
				</div>
			</div>
			<div class="item">
				<div class="key">联系人：</div>
				<div class="value">
					<el-input v-model="concat" placeholder="请输入联系人" @input="fetchData()" clearable></el-input>
				</div>
			</div>
			<div class="item">
				<div class="key">电话：</div>
				<div class="value">
					<el-input v-model="phone" placeholder="请输入电话" @input="fetchData()" clearable></el-input>
				</div>
			</div>
			<div class="button">
				<el-button type="primary" @click="multiDelete">批量删除</el-button>
			</div>
			<div class="button">
				<el-button type="primary" @click="multiExport">全部导出</el-button>
			</div>
			<div class="button">
				<el-button type="primary" @click="newData">添加用户</el-button>
			</div>
		</div>
		<div class="table" v-loading="loading">
			<el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" stripe style="width: 100%"
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55">
				</el-table-column>
				<el-table-column prop="index" label="序号" width="100" align="center">
				</el-table-column>
				<el-table-column prop="company" label="企业名称" width="400" align="center">
				</el-table-column>
				<el-table-column prop="concat" label="联系人" width="250" align="center">
				</el-table-column>
				<el-table-column prop="phone" label="手机" width="200" align="center">
				</el-table-column>
				<el-table-column prop="email" label="邮箱" align="center">
				</el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<div class="table-operation">
							<div class="operation-item">
								<router-link :to="{path:'/edit',query:{id:scope.row.id}}">编辑</router-link>
							</div>
							<div class="operation-item">
								<el-button size="small" type="primary" @click="deleteThis(scope.row.id)">删除</el-button>
							</div>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="pager">
			<el-pagination @current-change="handleCurrentChange" :current-page="currentPage4" :page-size="pageSize"
				layout="total, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'vip',
		data() {
			return {
				company: '',
				range: [],
				rangeVal: [],
				props: {
					value: 'label'
				},
				concat: '',
				phone: '',
				tableData: [
					// {
					// 	index:1,
					// 	company:'西安可信时间认证服务有限公司',
					// 	concat:'联系人',
					// 	phone:'15930115095',
					// 	email:'1261639306@qq.com',
					// 	id:1
					// }
				],
				multipleSelection: [],
				currentPage4: 1,
				pageSize: 10,
				total: 0,
				loading: true
			};
		},
		methods: {
			newData() {
				this.$router.push({
					path: '/edit',
					query: {
						id: ''
					}
				})
			},
			handleCurrentChange(e) {
				this.currentPage4 = e
				this.fetchData()
			},
			handleSelectionChange(val) {
				this.multipleSelection = val
			},
			deleteThis(id) {
				this.$confirm('确认要删除此会员吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.doDelete(id)
				}).catch(() => {});
			},
			multiDelete() {
				this.$confirm('确认要批量删除会员吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let ids = this.$pub.arrayToString(this.multipleSelection, 'id')
					this.doDelete(ids)
				}).catch(() => {});
			},
			doDelete(id) {
				const loading = this.$loading({
					lock: true,
					text: '请稍候'
				})
				this.$api.deleteVip({
					id: id
				}, res => {
					loading.close()
					if (res.code == 200) {
						this.currentPage4 = 1
						this.fetchData()
						this.$message({
							type: 'success',
							message: '删除会员成功！',
							duration: 1500,
							onClose: () => {

							}
						});
					}
				})
			},
			multiExport() {
				const loading = this.$loading({
					lock: true,
					text: '请稍候'
				})
				this.$api.exportVip({}, res => {
					loading.close()
					if (res.code == 200) {
						window.open(res.data.meberInfo)
					}
				})
			},
			fetchData() {
				this.loading = true
				this.$api.vipList({
					pageNum: this.currentPage4,
					pageSize: this.pageSize,
					enterpriseName: this.company,
					contacts: this.concat,
					contactNumber:this.phone,
					category:  this.rangeVal.join(','),
				}, res => {
					this.loading = false
					if (res.code == 200) {
						let list = res.data.list
						let array = new Array()
						list.forEach((item, index) => {
							let obj = {
								index: (index + 1) + 10 * (this.currentPage4 - 1),
								company: item.enterpriseName,
								concat: item.contacts == null ? '-' : item.contacts,
								phone: item.contactNumber == null ? '-' : item.contactNumber,
								email: item.email == null ? '-' : item.email,
								id: item.id
							}
							array.push(obj)
						})
						this.tableData = array
						this.total = res.data.total
					}
				})
			},
			fetchRange() {
				this.$api.vipRange({}, res => {
					if (res.code == 200) {
						this.range = res.data
					}
				})
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
			this.fetchData()
			this.fetchRange()
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped"></style>
