<template>
	<div class="basic article">
		<div class="bread">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/article' }">文章管理</el-breadcrumb-item>
				<el-breadcrumb-item>文章列表</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="list">
			<div class="category">
				<div class="category-title">
					栏目导航
				</div>
				<el-tree :data="category" node-key="id" :default-checked-keys="[1]" :highlight-current="true" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
			</div>
			<div class="flow">
				<div class="filtrate">
					<div class="item">
						<div class="key">标题：</div>
						<div class="value">
							<el-input v-model="title" placeholder="请输入标题" @input="fetchList()" clearable></el-input>
						</div>
					</div>
					<!-- <div class="item">
						<div class="key">是否已发布：</div>
						<div class="value">
							<el-select v-model="value" placeholder="请选择发布状态" @change="fetchList()">
								<el-option v-for="(item,index) in options" :key="index" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</div>
					</div> -->
					<div class="button">
						<el-button type="primary" @click="multiDelete">批量删除</el-button>
					</div>
					<div class="button">
						<el-button type="primary" @click="newData">添加文章</el-button>
					</div>
				</div>
				<div class="table" v-loading="loading">
					<el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" stripe style="width: 100%"
						@selection-change="handleSelectionChange">
						<el-table-column type="selection" width="55">
						</el-table-column>
						<el-table-column prop="index" label="序号" width="50" align="center">
						</el-table-column>
						<el-table-column prop="title" label="标题" width="450" align="center">
						</el-table-column>
						<el-table-column prop="source" label="来源" width="200" align="center">
						</el-table-column>
						<el-table-column prop="author" label="作者" width="100" align="center">
						</el-table-column>
						<el-table-column prop="date" label="发布时间" width="160" align="center">
						</el-table-column>
						<!-- <el-table-column prop="issue" label="是否发布" width="90" align="center">
						</el-table-column> -->
						<el-table-column prop="times" label="浏览次数" width="90" align="center">
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<div class="table-operation">
									<div class="operation-item">
										<router-link :to="{path:'/newly',query:{id:scope.row.id}}">编辑</router-link>
									</div>
									<div class="operation-item">
										<el-button size="small" type="primary"
											@click="deleteThis(scope.row.id,scope.$index)">删除</el-button>
									</div>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="pager">
					<el-pagination @current-change="handleCurrentChange" :current-page="currentPage4"
						:page-size="pageSize" layout="total, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'article',
		data() {
			return {
				title: '',
				options: [{
					value: '1',
					label: '是'
				}, {
					value: '2',
					label: '否'
				}],
				value: '',
				category: [],
				categoryId:'',
				defaultProps: {
					children: 'children',
					label: 'label'
				},
				tableData: [
					// {
					// 	index: 1,
					// 	title: '国家授时中心联合中国信息协会首推可信时间认证服务',
					// 	source: 'https://evidence.bjzjtp.com/',
					// 	author: '管理员',
					// 	date: '2021.12.06 16:43',
					// 	issue: '是',
					// 	times: 123,
					// 	id: 1
					// }
				],
				multipleSelection:[],
				currentPage4: 1,
				pageSize: 10,
				total: 0,
				loading:true
			};
		},
		methods: {
			newData() {
				this.$router.push({
					path:'/newly',
					query:{
						id:''
					}
				})
			},
			handleNodeClick(e){
				this.currentPage4 = 1
				this.categoryId = e.id
				this.fetchList()
			},
			handleCurrentChange(e) {
				this.currentPage4 = e
				this.fetchList()
			},
			handleSelectionChange(val) {
				this.multipleSelection = val
			},
			deleteThis(id) {
				this.$confirm('确认要删除这篇文章吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.doDelete(id)
				}).catch(() => {});
			},
			multiDelete() {
				this.$confirm('确认要批量删除文章吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let ids = this.$pub.arrayToString(this.multipleSelection,'id')
					this.doDelete(ids)
				}).catch(() => {});
			},
			doDelete(id){
				const loading = this.$loading({
					lock:true,
					text:'请稍候'
				})
				this.$api.deleteNews({
					id:id
				},res=>{
					loading.close()
					if(res.code==200){
						this.currentPage4 = 1
						this.fetchList()
						this.$message({
							type: 'success',
							message: '删除文章成功！',
							duration: 1500,
							onClose: () => {
								
							}
						});
					}
				})
			},
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},
			fetchData(){
				this.$api.newsCategory({},res=>{
					if(res.code==200){
						this.category = res.data
						this.categoryId = res.data[0]['id']
						this.fetchList()
					}
				})
			},
			fetchList(){
				this.loading = true
				this.$api.newsList({
					pageNum:this.currentPage4,
					pageSize:this.pageSize,
					articleType:this.categoryId,
					articleTitle:this.title,
					status:this.value
				},res=>{
					this.loading = false
					if(res.code==200){
						let list = res.data.list
						let array = new Array()
						list.forEach((item,index)=>{
							let obj = {
								index:(index+1)+10*(this.currentPage4-1),
								title: item.articleTitle.length>28?item.articleTitle.substring(0,28)+'...':item.articleTitle,
								source: item.articleSource==null?'-':item.articleSource,
								author: item.articleAuthor==null?'-':item.articleAuthor,
								date: item.createAt,
								issue: item.releaseStatusName,
								times: item.browseViewNum==null?0:item.browseViewNum,
								id: item.id
							}
							array.push(obj)
						})
						this.tableData = array
						this.total = res.data.total
					}
				})
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
			this.fetchData()
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	.list {
		width: 100%;
		height: calc(100% - 50px);
		display: flex;
		justify-content: space-between;

		.category {
			width: 200px;
			height: 100%;
			border-right: 1px solid @vue-border-color;
			box-sizing: border-box;
			.category-title{
				width: 100%;
				height: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: @vue-font-size-sm;
				border-bottom: 1px solid @vue-border-color;
				box-sizing: border-box;
				margin-bottom: @vue-spacing-col-base;
			}
		}

		.flow {
			width: calc(100% - 200px);
		}
	}
</style>
