tinymce.addI18n('zh_CN',{
"Redo": "恢复",
"Undo": "撤销",
"Cut": "剪切",
"Copy": "复制",
"Paste": "粘贴",
"Select all": "全选",
"New document": "新建文档",
"Ok": "确定",
"Cancel": "取消",
"Visual aids": "网格线",
"Bold": "粗体",
"Italic": "斜体",
"Underline": "下划线",
"Strikethrough": "删除线",
"Superscript": "上标",
"Subscript": "下标",
"Clear formatting": "清除格式",
"Align left": "左对齐",
"Align center": "居中",
"Align right": "右对齐",
"Justify": "两端对齐",
"Bullet list": "符号列表",
"Numbered list": "数字列表",
"Decrease indent": "减少缩进",
"Increase indent": "增加缩进",
"Close": "关闭",
"Formats": "格式",
"Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X\/C\/V keyboard shortcuts instead.": "当前浏览器不支持访问剪贴板，请使用快捷键Ctrl+X/C/V复制粘贴",
"Headers": "标题",
"Header 1": "标题1",
"Header 2": "标题2",
"Header 3": "标题3",
"Header 4": "标题4",
"Header 5": "标题5",
"Header 6": "标题6",
"Headings": "标题",
"Heading 1": "标题1",
"Heading 2": "标题2",
"Heading 3": "标题3",
"Heading 4": "标题4",
"Heading 5": "标题5",
"Heading 6": "标题6",
"Preformatted": "预格式化",
"Div": "Div区块",
"Pre": "预格式文本",
"Code": "代码",
"Paragraph": "段落",
"Blockquote": "引用",
"Inline": "文本",
"Blocks": "区块",
"Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.": "当前为纯文本粘贴模式，再次点击可以回到普通粘贴模式。",
"Fonts": "字体",
"Font Sizes": "字号",
"Class": "Class",
"Browse for an image": "浏览图像",
"OR": "或",
"Drop an image here": "拖放一张图片文件至此",
"Upload": "上传",
"Block": "块",
"Align": "对齐",
"Default": "默认",
"Circle": "空心圆",
"Disc": "实心圆",
"Square": "方块",
"Lower Alpha": "小写英文字母",
"Lower Greek": "小写希腊字母",
"Lower Roman": "小写罗马字母",
"Upper Alpha": "大写英文字母",
"Upper Roman": "大写罗马字母",
"Anchor...": "锚点...",
"Name": "名称",
"Id": "id",
"Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.": "id应该以字母开头，后跟字母、数字、横线、点、冒号或下划线。",
"You have unsaved changes are you sure you want to navigate away?": "你对文档的修改尚未保存，确定离开吗？",
"Restore last draft": "恢复上次的草稿",
"Special characters...": "特殊字符...",
"Source code": "HTML源码",
"Insert\/Edit code sample": "插入/编辑代码示例",
"Language": "语言",
"Code sample...": "代码示例...",
"Color Picker": "选取颜色",
"R": "R",
"G": "G",
"B": "B",
"Left to right": "从左到右",
"Right to left": "从右到左",
"Emoticons...": "表情符号...",
"Metadata and Document Properties": "元数据和文档属性",
"Title": "标题",
"Keywords": "关键词",
"Description": "描述",
"Robots": "机器人",
"Author": "作者",
"Encoding": "编码",
"Fullscreen": "全屏",
"Action": "操作",
"Shortcut": "快捷键",
"Help": "帮助",
"Address": "地址",
"Focus to menubar": "移动焦点到菜单栏",
"Focus to toolbar": "移动焦点到工具栏",
"Focus to element path": "移动焦点到元素路径",
"Focus to contextual toolbar": "移动焦点到上下文菜单",
"Insert link (if link plugin activated)": "插入链接 (如果链接插件已激活)",
"Save (if save plugin activated)": "保存(如果保存插件已激活)",
"Find (if searchreplace plugin activated)": "查找(如果查找替换插件已激活)",
"Plugins installed ({0}):": "已安装插件 ({0})：",
"Premium plugins:": "优秀插件：",
"Learn more...": "了解更多...",
"You are using {0}": "你正在使用 {0}",
"Plugins": "插件",
"Handy Shortcuts": "快捷键",
"Horizontal line": "水平分割线",
"Insert\/edit image": "插入/编辑图片",
"Image description": "图片描述",
"Source": "地址",
"Dimensions": "大小",
"Constrain proportions": "保持宽高比",
"General": "常规",
"Advanced": "高级",
"Style": "样式",
"Vertical space": "垂直边距",
"Horizontal space": "水平边距",
"Border": "边框",
"Insert image": "插入图片",
"Image...": "图片...",
"Image list": "图片列表",
"Rotate counterclockwise": "逆时针旋转",
"Rotate clockwise": "顺时针旋转",
"Flip vertically": "垂直翻转",
"Flip horizontally": "水平翻转",
"Edit image": "编辑图片",
"Image options": "图片选项",
"Zoom in": "放大",
"Zoom out": "缩小",
"Crop": "裁剪",
"Resize": "调整大小",
"Orientation": "方向",
"Brightness": "亮度",
"Sharpen": "锐化",
"Contrast": "对比度",
"Color levels": "色阶",
"Gamma": "伽马值",
"Invert": "反转",
"Apply": "应用",
"Back": "后退",
"Insert date\/time": "插入日期/时间",
"Date\/time": "日期/时间",
"Insert\/Edit Link": "插入/编辑链接",
"Insert\/edit link": "插入/编辑链接",
"Text to display": "显示文字",
"Url": "地址",
"Open link in...": "链接打开方式...",
"Current window": "当前窗口打开",
"None": "在当前窗口/框架打开",
"New window": "在新窗口打开",
"Remove link": "删除链接",
"Anchors": "锚点",
"Link...": "链接...",
"Paste or type a link": "粘贴或输入链接",
"The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?": "你所填写的URL地址为邮件地址，需要加上mailto:前缀吗？",
"The URL you entered seems to be an external link. Do you want to add the required http:\/\/ prefix?": "你所填写的URL地址属于外部链接，需要加上http://:前缀吗？",
"Link list": "链接列表",
"Insert video": "插入视频",
"Insert\/edit video": "插入/编辑视频",
"Insert\/edit media": "插入/编辑媒体",
"Alternative source": "替代资源",
"Alternative image URL": "资源备用地址",
"Media poster (Image URL)": "封面(图片地址)",
"Paste your embed code below:": "将内嵌代码粘贴在下面:",
"Embed": "内嵌",
"Media...": "多媒体...",
"Nonbreaking space": "不间断空格",
"Page break": "分页符",
"Paste as text": "粘贴为文本",
"Preview": "预览",
"Print...": "打印...",
"Save": "保存",
"Find": "查找",
"Replace with": "替换为",
"Replace": "替换",
"Replace all": "替换全部",
"Previous": "上一个",
"Next": "下一个",
"Find and replace...": "查找并替换...",
"Could not find the specified string.": "未找到搜索内容。",
"Match case": "区分大小写",
"Find whole words only": "全单词匹配",
"Spell check": "拼写检查",
"Ignore": "忽略",
"Ignore all": "忽略全部",
"Finish": "完成",
"Add to Dictionary": "添加到字典",
"Insert table": "插入表格",
"Table properties": "表格属性",
"Delete table": "删除表格",
"Cell": "单元格",
"Row": "行",
"Column": "列",
"Cell properties": "单元格属性",
"Merge cells": "合并单元格",
"Split cell": "拆分单元格",
"Insert row before": "在上方插入",
"Insert row after": "在下方插入",
"Delete row": "删除行",
"Row properties": "行属性",
"Cut row": "剪切行",
"Copy row": "复制行",
"Paste row before": "粘贴到上方",
"Paste row after": "粘贴到下方",
"Insert column before": "在左侧插入",
"Insert column after": "在右侧插入",
"Delete column": "删除列",
"Cols": "列",
"Rows": "行",
"Width": "宽",
"Height": "高",
"Cell spacing": "单元格外间距",
"Cell padding": "单元格内边距",
"Show caption": "显示标题",
"Left": "左对齐",
"Center": "居中",
"Right": "右对齐",
"Cell type": "单元格类型",
"Scope": "范围",
"Alignment": "对齐方式",
"H Align": "水平对齐",
"V Align": "垂直对齐",
"Top": "顶部对齐",
"Middle": "垂直居中",
"Bottom": "底部对齐",
"Header cell": "表头单元格",
"Row group": "行组",
"Column group": "列组",
"Row type": "行类型",
"Header": "表头",
"Body": "表体",
"Footer": "表尾",
"Border color": "边框颜色",
"Insert template...": "插入模板...",
"Templates": "模板",
"Template": "模板",
"Text color": "文字颜色",
"Background color": "背景色",
"Custom...": "自定义...",
"Custom color": "自定义颜色",
"No color": "无",
"Remove color": "删除颜色",
"Table of Contents": "目录",
"Show blocks": "显示区块边框",
"Show invisible characters": "显示不可见字符",
"Word count": "字数统计",
"Words: {0}": "字数：{0}",
"{0} words": "{0} 个字",
"File": "文件",
"Edit": "编辑",
"Insert": "插入",
"View": "查看",
"Format": "格式",
"Table": "表格",
"Tools": "工具",
"Powered by {0}": "Powered by {0}",
"Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help": "在编辑区按ALT+F9打开菜单，按ALT+F10打开工具栏，按ALT+0查看帮助",
"Image title": "图片标题",
"Border width": "边框宽度",
"Border style": "边框样式",
"Error": "错误",
"Warn": "警告",
"Valid": "有效",
"To open the popup, press Shift+Enter": "此快捷为软回车（插入<br>）",
"Rich Text Area. Press ALT-0 for help.": "编辑区. 按Alt+0键打开帮助",
"System Font": "默认字体",
"Failed to upload image: {0}": "图片上传失败: {0}",
"Failed to load plugin: {0} from url {1}": "插件加载失败: {0} - {1}",
"Failed to load plugin url: {0}": "插件加载失败: {0}",
"Failed to initialize plugin: {0}": "插件初始化失败: {0}",
"example": "示例",
"Search": "查找",
"All": "全部",
"Currency": "货币",
"Text": "文本",
"Quotations": "引用",
"Mathematical": "数学运算符",
"Extended Latin": "拉丁语扩充",
"Symbols": "符号",
"Arrows": "箭头",
"User Defined": "自定义",
"dollar sign": "美元",
"currency sign": "货币",
"euro-currency sign": "欧元",
"colon sign": "冒号",
"cruzeiro sign": "克鲁赛罗币",
"french franc sign": "法郎",
"lira sign": "里拉",
"mill sign": "密尔",
"naira sign": "奈拉",
"peseta sign": "比塞塔",
"rupee sign": "卢比",
"won sign": "韩元",
"new sheqel sign": "新谢克尔",
"dong sign": "越南盾",
"kip sign": "老挝基普",
"tugrik sign": "图格里克",
"drachma sign": "德拉克马",
"german penny symbol": "德国便士",
"peso sign": "比索",
"guarani sign": "瓜拉尼",
"austral sign": "澳元",
"hryvnia sign": "格里夫尼亚",
"cedi sign": "塞地",
"livre tournois sign": "里弗弗尔",
"spesmilo sign": "一千spesoj的货币符号，该货币未使用",
"tenge sign": "坚戈",
"indian rupee sign": "印度卢比",
"turkish lira sign": "土耳其里拉",
"nordic mark sign": "北欧马克",
"manat sign": "马纳特",
"ruble sign": "卢布",
"yen character": "日元",
"yuan character": "人民币元",
"yuan character, in hong kong and taiwan": "元的繁体字",
"yen\/yuan character variant one": "元（大写）",
"Loading emoticons...": "正在加载表情文字...",
"Could not load emoticons": "不能加载表情文字",
"People": "人类",
"Animals and Nature": "动物和自然",
"Food and Drink": "食物和饮品",
"Activity": "活动",
"Travel and Places": "旅游和地点",
"Objects": "物件",
"Flags": "旗帜",
"Characters": "字数",
"Characters (no spaces)": "字数（不含空格）",
"Error: Form submit field collision.": "错误: 表单提交字段冲突.",
"Error: No form element found.": "错误: 未找到可用的form.",
"Update": "更新",
"Color swatch": "颜色样本",
"Turquoise": "青绿",
"Green": "绿色",
"Blue": "蓝色",
"Purple": "紫色",
"Navy Blue": "海军蓝",
"Dark Turquoise": "深蓝绿色",
"Dark Green": "暗绿",
"Medium Blue": "中蓝",
"Medium Purple": "中紫",
"Midnight Blue": "深蓝",
"Yellow": "黄色",
"Orange": "橙色",
"Red": "红色",
"Light Gray": "浅灰",
"Gray": "灰色",
"Dark Yellow": "暗黄",
"Dark Orange": "暗橙",
"Dark Red": "暗红",
"Medium Gray": "中灰",
"Dark Gray": "深灰",
"Black": "黑色",
"White": "白色",
"Switch to or from fullscreen mode": "切换全屏模式",
"Open help dialog": "打开帮助对话框",
"history": "历史",
"styles": "样式",
"formatting": "格式化",
"alignment": "对齐",
"indentation": "缩进",
"permanent pen": "记号笔",
"comments": "注释",
"Anchor": "锚点",
"Special character": "特殊字符",
"Code sample": "代码示例",
"Color": "颜色",
"Emoticons": "表情",
"Document properties": "文档属性",
"Image": "图片",
"Insert link": "插入链接",
"Target": "目标",
"Link": "链接",
"Poster": "封面",
"Media": "音视频",
"Print": "打印",
"Prev": "上一个",
"Find and replace": "查找并替换",
"Whole words": "全字匹配",
"Spellcheck": "拼写检查",
"Caption": "标题",
"Insert template": "插入模板",
//以下为补充汉化内容 by 莫若卿
"Code view": "代码区域",
"Select...": "选择...",
"Format Painter": "格式刷",
"No templates defined.": "无内置模板",
"Special character...": "特殊字符...",
"Open link": "打开链接",
"None": "无",
"Count": "统计",
"Document": "整个文档",
"Selection": "选取部分",
"Words": "字词数",
"{0} characters": "{0} 个字符",
"Alternative source URL": "替代资源地址",
"Alternative description": "替代说明文字",
"Accessibility": "可访问性",
"Image is decorative": "仅用于装饰",
//5.6新增
"Line height": "行高",
"Cut column": "剪切列",
"Copy column": "复制列",
"Paste column before": "粘贴到前方",
"Paste column after": "粘贴到后方",
"Copy column": "复制列",
//帮助窗口内的文字
"Version": "版本",
"Keyboard Navigation": "键盘导航",
"Open popup menu for split buttons": "该组合键的作用是软回车（插入br）",
});