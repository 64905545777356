<template>
	<div class="basic user">
		<div class="bread">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/user' }">用户管理</el-breadcrumb-item>
				<el-breadcrumb-item>用户列表</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="filtrate">
			<div class="item">
				<div class="key">姓名：</div>
				<div class="value">
					<el-input v-model="name" placeholder="请输入姓名" @input="fetchData()" clearable></el-input>
				</div>
			</div>
			<div class="item">
				<div class="key">部门：</div>
				<div class="value">
					<el-cascader v-model="departmentVal" :options="department" :props="departProps" placeholder="请选择部门" @change="fetchData()" collapse-tags clearable></el-cascader>
				</div>
			</div>
			<div class="item">
				<div class="key">角色：</div>
				<div class="value">
					<el-cascader v-model="roleVal" :options="role" :props="props" placeholder="请选择角色" @change="fetchData()" collapse-tags clearable></el-cascader>
				</div>
			</div>
			<div class="button">
				<el-button type="primary" @click="multiDelete">批量删除</el-button>
			</div>
			<div class="button">
				<el-button type="primary" @click="newData">添加用户</el-button>
			</div>
		</div>
		<div class="table" v-loading="loading">
			<el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" stripe style="width: 100%"
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55">
				</el-table-column>
				<el-table-column prop="index" label="序号" width="100" align="center">
				</el-table-column>
				<el-table-column prop="name" label="姓名" width="260" align="center">
				</el-table-column>
				<el-table-column prop="account" label="用户账号" width="260" align="center">
				</el-table-column>
				<el-table-column prop="sex" label="性别" width="100" align="center">
				</el-table-column>
				<el-table-column prop="department" label="部门" align="center">
				</el-table-column>
				<el-table-column prop="role" label="角色" align="center">
				</el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<div class="table-operation">
							<div class="operation-item">
								<router-link :to="{path:'/add',query:{id:scope.row.id}}">编辑</router-link>
							</div>
							<div class="operation-item">
								<el-button size="small" type="primary" @click="deleteThis(scope.row.id)">删除</el-button>
							</div>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="pager">
			<el-pagination @current-change="handleCurrentChange" :current-page="currentPage4" :page-size="pageSize"
				layout="total, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'user',
		data() {
			return {
				name:'',
				multipleSelection:[],
				tableData: [
					// {
					// 	index:1,
					// 	name:'超级管理员',
					// 	account:'admin',
					// 	sex:'男',
					// 	department:'最高董事会',
					// 	role:'董事长',
					// 	id:1
					// }
				],
				currentPage4: 1,
				pageSize: 10,
				total: 0,
				loading: true,
				department: [],
				departmentVal: [],
				departProps:{
					value:'label'
				},
				props:{
					value:'label'
				},
				role: [],
				roleVal: [],
			};
		},
		methods: {
			newData() {
				this.$router.push({
					path:'/add',
					query:{
						id:''
					}
				})
			},
			handleCurrentChange(e) {
				this.currentPage4 = e
				this.fetchData()
			},
			handleSelectionChange(val){
				this.multipleSelection = val
			},
			deleteThis(id){
				this.$confirm('确认要删除这个用户吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.doDelete(id)
				}).catch(() => {});
			},
			multiDelete(){
				this.$confirm('确认要批量删除用户吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let ids = this.$pub.arrayToString(this.multipleSelection,'id')
					this.doDelete(ids)
				}).catch(() => {});
			},
			doDelete(id){
				const loading = this.$loading({
					lock:true,
					text:'请稍候'
				})
				this.$api.deleteUser({
					userId:id
				},res=>{
					loading.close()
					if(res.code==200){
						this.currentPage4 = 1
						this.fetchData()
						this.$message({
							type: 'success',
							message: '删除用户成功！',
							duration: 1500,
							onClose: () => {
								
							}
						});
					}
				})
			},
			fetchDepartment(){
				this.$api.userDepartment({},res=>{
					if(res.code==200){
						this.department = res.data
					}
				})
			},
			fetchRole(){
				this.$api.userRole({},res=>{
					if(res.code==200){
						this.role = res.data
					}
				})
			},
			fetchData(){
				this.loading = true
				this.$api.userList({
					realName:this.name,
					pageNum:this.currentPage4,
					deptId:this.departmentVal.join(','),
					roleList:this.roleVal.join(','),
					pageSize:this.pageSize
				},res=>{
					this.loading = false
					if(res.code==200){
						let list = res.data.list
						let array = new Array()
						list.forEach((item,index)=>{
							let obj = {
								index:(index+1)+10*(this.currentPage4-1),
								name:item.realName,
								account:item.username,
								sex:item.gender==null?'-':item.gender,
								department:item.deptId==null?'-':(item.deptId.length>18?item.deptId.substring(0,16)+'...':item.deptId),
								role:item.roleList==null?'-':(item.roleList.length>19?item.roleList.substring(0,17)+'...':item.roleList),
								id:item.userId
							}
							array.push(obj)
						})
						this.tableData = array
						this.total = res.data.total
					}
				})
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
			this.fetchDepartment()
			this.fetchRole()
			this.fetchData()
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	
</style>
