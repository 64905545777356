import router from '../router/index.js'
let file_url = ''
let requestUrl = '/api'
//同步获取本地存储并返回，此处不得改为异步，否则return数据错误
function getStorage(name) {
	if (!name) {
		return
	} else {
		return localStorage.getItem(name)
	}
}
//跳转至登录页
function routerToLogin() {
	router.push({
		path: '/login'
	})
	location.reload()
}
// 本地存储
function setStorage(key, data) {
	localStorage.setItem(key, data)
}
// 计时器
function timer(second, callback) {
	let interval = setInterval(() => {
		if (second > 0) {
			second--
		} else {
			second = 60
			clearInterval(interval)
		}
		callback(second)
	}, 1000)
}
// 转化分数
function tranStar(score) {
	score = parseFloat(score)
	let intScore = parseInt(score)
	let cha = score - intScore
	let star = new Array()
	for (let i = 1; i <= 5; i++) {
		if (i <= intScore) {
			star.push('../../static/star/full.png')
		} else if (cha > 0 && i == intScore + 1) {
			star.push('../../static/star/half.png')
		} else if (i > intScore + 1 || cha == 0) {
			star.push('../../static/star/grey.png')
		}
	}
	return star
}
// 初始时间
function initDate() {
	// 开始日期
	let date = new Date()
	let str = date.getFullYear() + '-' + ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() +
		1)) + '-' + date.getDate()
	let timeStamp = date.getTime()
	let eTimeStamp = timeStamp + (60 * 60 * 24 * 3000)
	let eDate = new Date(eTimeStamp)
	let Y = eDate.getFullYear() + '-';
	let M = (eDate.getMonth() + 1 < 10 ? '0' + (eDate.getMonth() + 1) : eDate.getMonth() + 1) + '-';
	let D = eDate.getDate() + ' '
	let str2 = Y + M + D
	return {
		start: str,
		end: str2
	}
}
// 计算天数差值
function difference(str, str2) {
	let aDate, oDate1, oDate2, iDays
	aDate = str.split("-")
	oDate1 = new Date(aDate[1] + '/' + aDate[2] + '/' + aDate[0]) //转换为9-25-2017格式
	aDate = str2.split("-")
	oDate2 = new Date(aDate[1] + '/' + aDate[2] + '/' + aDate[0])
	iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24) //把相差的毫秒数转换为天数
	return iDays
}
// 获取具体时间
function getDetailTime() {
	let date = new Date()
	let year = date.getFullYear()
	let month = date.getMonth() + 1
	let day = date.getDate()
	let str = year + '.' + month + '.' + day + ' ' + date.getHours() + ':' + date.getMinutes()
	let timeStamp = Date.parse(date)
	return {
		year: year,
		month: month,
		day: day,
		str: str,
		timeStamp: timeStamp
	}
}
// 转化时间戳
function timeStampToTime(timeStamp) {
	return new Date(parseInt(timeStamp) * 1000).toLocaleString().replace(/:\d{1,2}$/, ' ')
}
// 获得一个年-月的数组
function timeArray(year, month) {
	let obj = getDetailTime()
	let nowYear = obj.year
	let nowMonth = obj.month
	let str = ''
	let array = new Array()
	// 如果注册年份等于今年
	if (year == nowYear) {
		for (let i = 0; i < nowMonth + 1 - month; i++) {
			str = nowYear + '-' + (month + i)
			array.push(str)
		}
	} else {
		// 如果注册年份不等于今年
		for (let i = 0; i <= nowYear - year; i++) {
			let m = 12
			// 第一年
			if (i == 0) {
				m = 13 - month
				for (let k = 0; k < m; k++) {
					str = (year + i) + '-' + (month + k)
					array.push(str)
				}
				continue
			}
			// 最后一年
			if (i == nowYear - year) {
				m = nowMonth
			}
			for (let k = 1; k <= m; k++) {
				str = (year + i) + '-' + (k)
				array.push(str)
			}
		}
	}
	return array
}
// 数组转逗号字符串
function arrayToString(array, key) {
	let str = new Array()
	for (let i = 0; i < array.length; i++) {
		str.push(array[i][key])
	}
	return str.join(',')
}
// urlscheme参数转对象
function parseQueryString(url) {
	let obj = {};
	let keyvalue = [];
	let key = "",
		value = "";
	let paraString = url.substring(url.indexOf("//") + 2, url.length).split("&");
	for (let i in paraString) {
		keyvalue = paraString[i].split("=");
		key = keyvalue[0];
		value = keyvalue[1];
		obj[key] = value;
	}
	return obj;
}
// 获取URL参数
function getQueryVariable(variable) {
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split("=");
		if (pair[0] == variable) {
			return pair[1];
		}
	}
	return (false);
}


export default {
	file_url: file_url,
	requestUrl: requestUrl,
	getStorage: getStorage,
	routerToLogin: routerToLogin,
	setStorage: setStorage,
	timer: timer,
	tranStar: tranStar,
	initDate: initDate,
	difference: difference,
	getDetailTime: getDetailTime,
	timeStampToTime: timeStampToTime,
	timeArray: timeArray,
	arrayToString: arrayToString,
	parseQueryString: parseQueryString,
	getQueryVariable: getQueryVariable
}
