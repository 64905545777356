<template>
	<div class="basic slide">
		<div class="bread">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/slide' }">轮播图管理</el-breadcrumb-item>
				<el-breadcrumb-item>轮播图列表</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="filtrate">
			<div class="button">
				<el-button type="primary" @click="newData">添加轮播图</el-button>
			</div>
		</div>
		<div class="table" v-loading="loading">
			<el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" stripe style="width: 100%"
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55">
				</el-table-column>
				<el-table-column prop="index" label="序号" width="100" align="center">
				</el-table-column>
				<el-table-column label="图片缩略图" width="500" align="center">
					<template slot-scope="scope">
						<div class="image-preview">
							<img :src="scope.row.url" >
						</div>
					</template>
				</el-table-column>
				<el-table-column label="图片链接" width="500" align="center">
					<template slot-scope="scope">
						<a :href="scope.row.link">{{scope.row.url}}</a>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<div class="table-operation">
							<div class="operation-item">
								<router-link :to="{path:'/image',query:{id:scope.row.id}}">编辑</router-link>
							</div>
							<div class="operation-item">
								<el-button size="small" type="primary" @click="deleteThis(scope.row.id)">删除</el-button>
							</div>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="pager">
			<el-pagination @current-change="handleCurrentChange" :current-page="currentPage4" :page-size="pageSize"
				layout="total, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'slide',
		data() {
			return {
				multipleSelection: [],
				tableData: [
					// {
					// 	index: 1,
					// 	url: '',
					// 	link: ''
					// }
				],
				currentPage4: 1,
				pageSize: 5,
				total: 0,
				loading: true,
			};
		},
		methods: {
			newData() {
				this.$router.push({
					path:'/image',
					query:{
						id:''
					}
				})
			},
			handleCurrentChange(e) {
				this.currentPage4 = e
				this.fetchData()
			},
			handleSelectionChange(val){
				this.multipleSelection = val
			},
			deleteThis(id){
				this.$confirm('确认要删除这个图片吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.doDelete(id)
				}).catch(() => {});
			},
			doDelete(id){
				const loading = this.$loading({
					lock:true,
					text:'请稍候'
				})
				this.$api.deleteBanner({
					id:id
				},res=>{
					loading.close()
					if(res.code==200){
						this.currentPage4 = 1
						this.fetchData()
						this.$message({
							type: 'success',
							message: '删除图片成功！',
							duration: 1500,
							onClose: () => {
								
							}
						});
					}
				})
			},
			fetchData(){
				this.loading = true
				this.$api.bannerList({
					pageNum:this.currentPage4,
					pageSize:this.pageSize
				},res=>{
					this.loading = false
					if(res.code==200){
						let list = res.data.list
						let array = new Array()
						list.forEach((item,index)=>{
							let obj = {
								index:(index+1)+10*(this.currentPage4-1),
								url: item.rotationImgUrl,
								link: item.rotationUrl,
								id:item.id
							}
							array.push(obj)
						})
						this.tableData = array
						this.total = res.data.total
					}
				})
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
			this.fetchData()
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	.image-preview{
		height: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		img{
			width: auto;
			display: block;
			height: 80px;
		}
	}
</style>
