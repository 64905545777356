<template>
	<div class="header">
		<div class="header-logo">
			<div class="fixation">
				<img src="../assets/logo@2x.png" >
			</div>
		</div>
		<div class="header-nav">
			<div class="fixation">
				<div class="header-main">
					<div class="header-item" v-for="(item,index) in navigation" :key="index">
						<router-link class="header-main-link" :to="{path:item.path}">{{item.name}}</router-link>
						<ul>
							<li v-for="(tab,tabIndex) in item['tabs']" :key="tabIndex">
								<router-link :to="{path:tab.path}">{{tab.name}}</router-link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'header',
		data() {
			return {
				navigation:[
					{
						path:'/index',
						name:'首页',
						tabs:[]
					},
					{
						path:'/dynamic',
						name:'行业新闻',
						tabs:[]
					},
					{
						path:'/concat',
						name:'联系我们',
						tabs:[]
					}
				]
			};
		},
		methods: {},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	.header{
		width: 100%;
		.header-logo{
			width: 100%;
			height: 110px;
			.fixation{
				display: flex;
				align-items: center;
				img{
					width: 400px;
				}
			}
		}
		.header-nav{
			width: 100%;
			height: 50px;
			background: @vue-color-primary;
			.fixation{
				.header-main{
					width: 100%;
					height: 100%;
					display: flex;
					.header-item{
						width: 150px;
						height: 100%;
						position: relative;
						.header-main-link{
							width: 100%;
							height: 100%;
							color: @vue-text-color-inverse;
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: @vue-font-size-sm;
						}
						.header-main-link:hover{
							background: @vue-bg-color-active;
						}
						ul{
							width: 100%;
							background: @vue-bg-color-grey;
							box-shadow: 0px 2px 8px @vue-border-color;
							display: none;
							li{
								width: 100%;
								height: 40px;
								a{
									width: 100%;
									height: 100%;
									display: flex;
									justify-content: center;
									align-items: center;
									font-size: @vue-font-size-min + 2;
									color: @vue-text-color;
								}
								a:hover{
									color: @vue-text-color-inverse;
									background: @vue-bg-color-active;
								}
							}
						}
					}
					.header-item:hover{
						ul{
							display: block;
							position: relative;
							z-index: 999;
						}
					}
				}
			}
		}
	}
</style>
