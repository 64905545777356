<template>
	<div class="list">
		<div class="fixation">
			<div class="table">
				<el-table :data="tableData" style="width: 100%">
					<el-table-column prop="enterpriseName" label="会员单位名称" width="295" align="center" />
					<el-table-column prop="certificateNo" label="证书编号" width="295" align="center" />
					<el-table-column prop="population" label="证书有效日期" width="295" align="center" />
					<el-table-column prop="registerAt" label="发证日期" width="295" align="center"/>
				</el-table>
			</div>
			<div class="page">
				<el-pagination @current-change="handleCurrentChange" :current-page="currentPage4" :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'list',
		data() {
			return {
				tableData:[],
				currentPage4:1,
				pageSize:15,
				total:0
			};
		},
		methods: {
			handleCurrentChange(e){
				this.currentPage4 = e
				this.fetchData()
			},
			fetchData(){
				this.$api.getVipList({
					pageNum:this.currentPage4,
					pageSize:this.pageSize
				},res=>{
					if(res.code==200){
						this.tableData = res.data.list
						this.total = res.data.total
					}
				})
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
			this.fetchData()
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	.list{
		width: 100%;
		padding: @vue-spacing-col-lg 0px;
		background: @vue-bg-color-grey;
		.fixation{
			background: @vue-bg-color;
			box-shadow: 0px 0px 10px @vue-border-color;
			.table{
				width: 100%;
				padding: 10px;
				box-sizing: border-box;
			}
			.page{
				width: 100%;
				height: 70px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
</style>
