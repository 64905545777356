// 身份证
function verify_identity($str) {
	let reg = /(^([\d]{15}|[\d]{18}|[\d]{17}x)$)/
	return reg.test($str)
}
// 邮箱
function verify_email($str) {
	let reg = /^[_\.0-9a-z-]+@([0-9a-z][0-9a-z-]+\.)+[a-z]{2,4}$/
	return reg.test($str)
}
// 邮编
function verify_zip($str) {
	let reg = /^[1-9]\d{5}$/
	return reg.test($str)
}

export default {
	verify_identity: verify_identity,
	verify_email: verify_email,
	verify_zip: verify_zip
}
