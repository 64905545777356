<template>
	<!-- <div class="banner">
		<div class="swiper-wrapper">
			<div class="swiper-slide" v-for="(item,index) in swiper" :key="index" v-show="active===index">
				<a :href="item.link"><img :src="item.src" alt=""></a>
			</div>
		</div>
		<div class="swiper-prev" @click="next">
			<img src="../assets/left.png">
		</div>
		<div class="swiper-next" @click="prev">
			<img src="../assets/right.png">
		</div>
		<div class="swiper-dots">
			<div :class="'swiper-dots-item '+(active===index?'swiper-dots-active':'')" v-for="(item,index) in swiper"
				:key="index" @click="active = index"></div>
		</div>
	</div> -->
	<div class="swiper">
		<el-carousel height="400px" :autoplay="true">
			<el-carousel-item v-for="(item,index) in swiper" :key="index">
				<a :href="item.rotationUrl"><img :src="item.rotationImgUrl" alt=""></a>
			</el-carousel-item>
		</el-carousel>
	</div>
</template>

<script>
	export default {
		name: 'banner',
		data() {
			return {
				active: 0,
				swiper: [],
				interval: ''
			};
		},
		methods: {
			next() {
				if (this.active < this.swiper.length - 1) {
					this.active++
				} else {
					this.active = 0
				}
			},
			prev() {
				if (this.active === 0) {
					this.active = this.swiper.length - 1
				} else {
					this.active--
				}
			},
			autoplay() {
				this.interval = setInterval(() => {
					this.next()
				}, 3000)
			},
			fetchData(){
				this.$api.getBanner({
					pageNum:1,
					pageSize:100
				},res=>{
					if(res.code==200){
						this.swiper = res.data.list
					}
				})
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
			clearInterval(this.interval)
			// this.autoplay()
			this.fetchData()
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	.swiper{
		width: 100%;
		height: 400px;
		a,
		img {
			width: 100%;
			height: 100%;
		}
	}
	.banner {
		width: 100%;
		height: 400px;
		position: relative;

		.swiper-wrapper,
		.swiper-slide,
		a,
		img {
			width: 100%;
			height: 100%;
		}

		.swiper-dots {
			width: 100%;
			height: 20px;
			position: absolute;
			bottom: 30px;
			left: 0px;
			z-index: 999;
			display: flex;
			justify-content: center;
			align-items: center;

			.swiper-dots-item {
				width: 20px;
				height: 20px;
				border-radius: @vue-border-radius-circle;
				background: @vue-bg-color-mask;
				margin-right: @vue-spacing-row-sm;
			}

			.swiper-dots-item:last-child {
				margin: 0px;
			}

			.swiper-dots-active {
				background: @vue-color-primary;
			}
		}

		.swiper-prev,
		.swiper-next {
			width: 50px;
			height: 50px;
			position: absolute;
			top: 50%;
			margin-top: -25px;
			background: @vue-bg-color-mask;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: @vue-border-radius-circle;

			img {
				width: 24px;
				height: 24px;
			}
		}

		.swiper-prev {
			left: 20px;
		}

		.swiper-next {
			right: 20px;
		}
	}
</style>
