<template>
	<div class="concat">
		<div class="fixation">
			<div class="title">
				联系我们
			</div>
			<div class="content">
				<div class="left">
					<div class="footer-line">
						<span>地址</span>
						：{{address}}
					</div>
					<div class="footer-line">
						<span>联系电话</span>
						：{{phone}}
					</div>
					<!-- <div class="footer-line">
						<span>传真</span>
						：{{fax}}
					</div> -->
					<div class="footer-line">
						<span>网址</span>
						：{{link}}
					</div>
					<div class="footer-line">
						<span>邮箱</span>
						：{{email}}
					</div>
				</div>
				<div class="right">
					<baidu-map class="bm-view" :center="map.center" :zoom="map.zoom" :scroll-wheel-zoom="true" @ready="handleMapReady">
						<bm-marker :position="map.position"></bm-marker>
						<bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
					</baidu-map>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'concat',
		data() {
			return {
				address:'北京市昌平区立业路5号院2#楼406',
				phone:'010-60608644',
				// fax:'010-60608644',
				link:'http://www.lbciia.org.cn/',
				email:'LBCIIA@126.COM',
				map: {
					center: {
						lng: 116.306932,
						lat: 40.115244
					},
					zoom: 11,
					position: {
						lng: 116.306932,
						lat: 40.115244
					}
				}
			};
		},
		methods: {
			handleMapReady() {
			
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	.concat{
		width: 100%;
		padding: @vue-spacing-col-lg 0px;
		box-sizing: border-box;
		background: @vue-bg-color-grey;
		.fixation{
			background: @vue-bg-color;
			box-shadow: 0px 0px 10px @vue-border-color;
			padding: @vue-spacing-col-lg @vue-spacing-row-lg;
			box-sizing: border-box;
			.title{
				width: 100%;
				height: 70px;
				text-align: center;
				line-height: 70px;
				font-size: @vue-font-size-lg;
				color: @vue-text-color-active;
				font-weight: bold;
				border-bottom: 1px solid @vue-border-color-active;
			}
			.content{
				width: 100%;
				height: 500px;
				display: flex;
				justify-content: space-between;
				margin-top: @vue-spacing-col-lg;
				.left{
					width: 50%;
					height: 100%;
					.footer-line{
						width: 100%;
						height: 50px;
						display: flex;
						align-items: center;
					}
					.footer-line:nth-child(1){
						margin-top: @vue-spacing-col-lg;
					}
				}
				.right{
					width: 50%;
					height: 100%;
					.bm-view{
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
</style>
