<template>
	<div class="basic add">
		<div class="block">
			<div class="block-title">
				登录信息
			</div>
			<div class="block-area">
				<div class="line">
					<div class="key">
						<span>*</span>
						账号：
					</div>
					<div class="value">
						<el-input v-model="account" maxlength="20" placeholder="请输入账号"></el-input>
						剩余{{20 - account.length}}字
					</div>
				</div>
				<div class="line">
					<div class="key">
						<span>*</span>
						密码：
					</div>
					<div class="value">
						<el-input v-model="password" type="password" :disabled="id==''?false:true" placeholder="请输入密码"></el-input>
						【注：密码默认为123456】
					</div>
				</div>
			</div>
		</div>
		<div class="block">
			<div class="block-title">
				基本信息信息
			</div>
			<div class="block-area">
				<div class="line">
					<div class="key">
						<span>*</span>
						姓名：
					</div>
					<div class="value">
						<el-input v-model="username" maxlength="20" placeholder="请输入姓名"></el-input>
						剩余{{20 - username.length}}字
					</div>
				</div>
				<div class="line">
					<div class="key">
						<span>*</span>
						性别：
					</div>
					<div class="value">
						<el-radio-group v-model="radio">
							<el-radio :label="1">男</el-radio>
							<el-radio :label="2">女</el-radio>
						</el-radio-group>
					</div>
				</div>
				<div class="line">
					<div class="key">
						<span>*</span>
						部门：
					</div>
					<div class="value">
						<el-cascader v-model="departmentVal" :options="department" :props="departProps" placeholder="请选择部门" collapse-tags clearable></el-cascader>
					</div>
				</div>
				<div class="line">
					<div class="key">
						<span>*</span>
						角色：
					</div>
					<div class="value">
						<el-cascader v-model="roleVal" :options="role" :props="props" placeholder="请选择角色" collapse-tags clearable></el-cascader>
					</div>
				</div>
				<div class="line">
					<div class="key">
						手机：
					</div>
					<div class="value">
						<el-input type="number" v-model="phone" placeholder="请输入手机号"></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						<span>*</span>
						邮箱：
					</div>
					<div class="value">
						<el-input type="email" v-model="email" placeholder="请输入邮箱"></el-input>
					</div>
				</div>
				<div class="line">
					<div class="key">
						<span>*</span>
						地址：
					</div>
					<div class="value">
						<el-input v-model="address" maxlength="50" placeholder="请输入地址"></el-input>
						剩余{{50 - address.length}}字
					</div>
				</div>
			</div>
		</div>
		<div class="submit">
			<el-button type="primary" @click="saveInfo">保存</el-button>
			<el-button @click="reset">重置</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'add',
		data() {
			return {
				account: '',
				password: '',
				username: '',
				radio: 1,
				department: [],
				departmentVal: [],
				departProps:{
					value:'label'
				},
				props:{
					multiple: true,
					value:'label'
				},
				role: [],
				roleVal: [],
				phone: '',
				email: '',
				address: '',
				id: ''
			};
		},
		methods: {
			fetchData() {
				const loading = this.$loading({
					lock:true,
					text:'请稍候'
				})
				this.$api.userInfo({
					userId: this.id
				}, res => {
					loading.close()
					if (res.code == 200) {
						this.account = res.data.username
						this.password = res.data.password
						this.username = res.data.realName
						this.radio = res.data.gender == '男' ? 1 : 2
						this.departmentVal = res.data.deptId.split(',')
						this.roleVal = res.data.roleList.split(',')
						this.phone = res.data.phone
						this.email = res.data.email
						this.address = res.data.address
					}
				})
			},
			fetchDepartment(){
				this.$api.userDepartment({},res=>{
					if(res.code==200){
						this.department = res.data
					}
				})
			},
			fetchRole(){
				this.$api.userRole({},res=>{
					if(res.code==200){
						this.role = res.data
					}
				})
			},
			checkForm(){
				if(this.account==''){
					this.$message.error('请输入账号');
					return false
				}
				if(this.password==''){
					this.$message.error('请输入密码');
					return false
				}
				if(this.username==''){
					this.$message.error('请输入姓名');
					return false
				}
				if(this.departmentVal.length==0){
					this.$message.error('请选择部门');
					return false
				}
				if(this.roleVal.length==0){
					this.$message.error('请选择角色');
					return false
				}
				if(!this.$ver.verify_email(this.email)){
					this.$message.error('请输入正确的邮箱');
					return false
				}
				if(this.address==''){
					this.$message.error('请输入地址');
					return false
				}
				return true
			},
			saveInfo() {
				let flag = this.checkForm()
				if(!flag){
					return
				}
				const loading = this.$loading({
					lock:true,
					text:'请稍候'
				})
				this.$api.editUser({
					username: this.account,
					password: this.password,
					realName: this.username,
					gender: this.radio == 1 ? '男' : '女',
					deptId: this.departmentVal.join(','),
					roleList: this.roleVal.join(','),
					phone: this.phone,
					email: this.email,
					address: this.address,
					userId: this.id
				}, res => {
					loading.close()
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: (this.id == '' ? '新增' : '编辑') + '用户成功！',
							duration: 1500,
							onClose: () => {
								this.$router.back()
							}
						});
					}
				})
			},
			reset() {
				this.account = ''
				this.password = ''
				this.username = ''
				this.radio = 1
				this.departmentVal = []
				this.roleVal = []
				this.phone = ''
				this.email = ''
				this.address = ''
			}
		},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
			this.id = this.$route.query.id
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
			if (this.id != '') {
				this.fetchData()
			}
			this.fetchDepartment()
			this.fetchRole()
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	.block {
		width: 100%;

		.block-title {
			width: 100%;
			height: 50px;
			background: @vue-bg-color-grey;
			padding: 0px @vue-spacing-row-base;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			border-left: 4px solid @vue-border-color-active;
		}

		.block-area {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 0px @vue-spacing-row-base;
			box-sizing: border-box;

			.line {
				width: 50%;
				height: 60px;
				display: flex;

				.key {
					width: 120px;
					height: 100%;
					display: flex;
					align-items: center;
					font-size: @vue-font-size-min + 2;

					span {
						color: @vue-text-color-red;
						font-size: @vue-font-size-base;
					}
				}

				.value {
					width: calc(100% - 150px);
					height: 100%;
					display: flex;
					align-items: center;

					.el-input,.el-cascader {
						width: 350px;
						margin-right: @vue-spacing-row-base;
					}
				}
			}
		}
	}

	.submit {
		width: 100%;
		margin-top: @vue-spacing-col-lg*2;
		display: flex;
		justify-content: center;

		.el-button {
			width: 200px;
		}

		.el-button:last-child {
			margin-left: @vue-spacing-row-lg*2;
		}
	}
</style>
