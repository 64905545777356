<template>
	<div class="footer">
		<div class="fixation">
			<div class="footer-left">
				<div class="footer-line">
					<span>地址</span>
					：{{address}}
				</div>
				<div class="footer-line">
					<span>联系电话</span>
					：{{phone}}
				</div>
				<div class="footer-line">
					<span>邮箱</span>
					：{{email}}
				</div>
				<div class="footer-line">
					<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="nofollow">京ICP备17051229号-5</a>
				</div>
				<div class="footer-line">
					<span>友情链接</span>
					：
					<a :href="item.link" v-for="(item,index) in links" :key="index"
						:rel="!item.nofollow ? 'nofollow' : ''" target="_blank">{{item.name}}</a>
				</div>
			</div>
			<div class="footer-right">
				<img :src="qrcode">
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'footer',
		data() {
			return {
				address: '北京市昌平区立业路5号院2#楼406',
				phone: '010-60608644',
				email: 'LBCIIA@126.COM',
				code: '',
				links: [{
						link: 'http://www.ciia.org.cn/',
						name: '中国信息协会',
						nofollow: true
					},
					{
						link: 'https://www.spp.gov.cn/',
						name: '最高人民检察院',
						nofollow: false
					},
					{
						link: 'http://www.court.gov.cn/',
						name: '最高人民法院',
						nofollow: false
					}
				],
				qrcode: require('../assets/qrcode.png')
			};
		},
		methods: {},
		beforeCreate: function() {
			//生命周期 -- 组件实例刚被创建，组件属性计算之前，如DATA属性等
		},
		created: function() {
			//生命周期 -- 组件实例创建完成，属性已绑定，但DOM还未生成，$el属性还不存在
		},
		beforeMount: function() {
			//模板编译/挂载之前
		},
		mounted: function() {
			//模板编译/挂载之后
		},
		beforeUpdate: function() {
			//组件更新之前
		},
		updated: function() {
			//组件更新之后
		},
		activated: function() {
			// for keep-alive，组件被激活时调用
		},
		deactivated: function() {
			// for keep-alive，组件被移除时调用
		},
		beforeDestory: function() {
			// 组件销毁调用
		},
		destoryed: function() {
			// 组件销毁后调用
		}
	};
</script>

<style lang="less" scoped="scoped">
	.footer {
		width: 100%;
		height: @vue-spacing-col-lg*2 + 180px;
		background: @vue-color-primary;
		padding: @vue-spacing-col-lg 0px;
		box-sizing: border-box;

		.fixation {
			display: flex;
			justify-content: space-between;

			.footer-left {
				width: 900px;
				height: 100%;

				.footer-line {
					width: 100%;
					height: 36px;
					color: @vue-text-color-inverse;
					display: flex;
					align-items: center;

					a {
						height: 100%;
						display: flex;
						align-items: center;
						color: @vue-text-color-inverse;
						margin-right: @vue-spacing-row-lg;
					}
				}
			}

			.footer-right {
				width: calc(100% - 900px);
				height: 100%;
				display: flex;
				justify-content: flex-end;
				align-items: center;

				img {
					width: 150px;
					display: block;
				}
			}
		}
	}
</style>